var _dec, _class;
import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from './session-service';
import { ToastService } from './toast-service';
import { Helper } from 'resources/extensions/helper';
import { HttpClient } from 'aurelia-fetch-client';
import { ToastType } from 'resources/helpers/enums';
import { ErrorPagesRedirection } from 'resources/extensions/redirections_functionality';
const AUTHORIZATION_HEADER = 'Authorization';
export let ApiInterceptor = (_dec = inject(SessionService, EventAggregator, Helper, HttpClient, ToastService, ErrorPagesRedirection), _dec(_class = class ApiInterceptor {
  constructor(sessionService, eventAggregator, helper, httpClient, toastService, errorPagesRedirection) {
    /**
     * @param {SessionService} sessionService
     * @param {AureliaEventAggregator} eventAggregator
     * @param {Helper} helper
     * @param {HttpClient} httpClient
     * @param {ToastService} toastService
     * @param {ErrorPagesRedirection} errorPagesRedirection
     */
    this.toastHasBeenFired = false;
    this.sessionService = sessionService;
    this.eventAggregator = eventAggregator;
    this.helper = helper;
    this.http = httpClient;
    this.toastService = toastService;
    this.errorPagesRedirection = errorPagesRedirection;
  }

  /**
   * @param {Request} request
   * @returns {Promise<Request>}
   */
  async request(request) {
    try {
      if (request.headers.has('X-Skip-Interceptor')) {
        return request;
      }
      const storedToken = await this.sessionService.getToken();
      if (storedToken && !request.headers.get(AUTHORIZATION_HEADER)) {
        const bearerToken = `Bearer ${storedToken}`;
        request.headers.append(AUTHORIZATION_HEADER, bearerToken);
      }
      return request;
    } catch (e) {
      this.toastService?.showToast(ToastType.ERROR, 'Something went wrong. If this error continues, please contact support.');
    }
  }
  async responseError() {
    return null;
  }
  async response(response) {
    try {
      this.errorPagesRedirection.handleRateLimitToken(response);
      if (response?.status === 429) {
        return this.errorPagesRedirection.handleRateLimitHttpStatus(response);
      }
      if (response?.status >= 400 && response?.status <= 599) {
        if (response?.status === 404) return;
        if (response?.status === 401) {
          await this.sessionService.clearSession();
          if (!response.headers?.get('content-type')?.includes('application/json')) return;
        }
        const data = await response.json();
        let msg = data.Message || data.message || (typeof data === 'string' ? data : undefined);
        if (msg?.includes('withdraw-concurrent-error')) {
          this.eventAggregator.publish('withdraw-concurrent-error', msg);
        } else if (msg) {
          msg.endsWith('.') ? msg : msg = msg + '.';
          this.toastService.showToast(ToastType.ERROR, msg.replace(/(?:\r\n|\r|\n)/g, '<br />'));
        }
        if (data.validationErrors) {
          let errorMsg = '';
          Object.keys(data.validationErrors).forEach((key, index) => {
            data.validationErrors[key].forEach(m => {
              errorMsg += `${m}<br>`;
            });
          });
          this.toastService.showToast(ToastType.ERROR, errorMsg);
        }
        new Error(msg);
      }
      return response;
    } catch (e) {
      this.toastService.showToast(ToastType.ERROR, 'Something went wrong. If this error continues, please contact support.');
    }
  }
}) || _class);