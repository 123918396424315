var _dec, _class;
import { inject, signalBindings } from 'aurelia-framework';
import { ApiService } from './api-service';
import { ReferralService } from './referral-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CurrencyService } from './currency-service';
import { UserDocumentService } from './user-document-service';
import { BlacklistService } from './blacklist-service';
import { CustomerService } from './customer-service';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';
import { debug, adminPanelUrl } from 'environment';
import { Helper } from 'resources/extensions/helper';
import countryToCurrency from 'country-to-currency';
import { SubscriptionService } from './subscription-service';
import { jwtDecode } from 'jwt-decode';
import { ScriptService } from './script-service';
import { SiteSettingService } from './site-setting-service';
import { HttpClient, json } from 'aurelia-fetch-client';
import { ToastType } from 'resources/helpers/enums';
import { ToastService } from './toast-service';
const SITE_DOWN_FIRED = 'site_down_toast_fired';
const RATE_LIMIT_FIRED = 'rate_limit_toast_fired';
const TOKEN_KEY = 'jwt_token';
const CURRENCY_KEY = 'currency';
const PREVIOUS_CURRENCY_KEY = 'previous_currency';
const PREVIOUS_PAYMENT_METHOD_KEY = 'previous_payment_method';
const REFERRAL_LINK_KEY = 'referral_link';
const ACTIVE_REFERRAL_LINK_KEY = 'active_referral_link';
const REFERRER_LINK_KEY = 'refferer_link';
const REFERRER_USER_ID = 'refferer_user_id';
const PURCHASED = 'purchased';
const GAME_CHARACTER = 'game_character_names';
const CART_KEY = 'cart';
const ADMIN_VIEW = 'admin_view';
const EMAIL_IN_REVIEW_KEY = 'email_in_review';
const CURRENT_ACCOUNTS_PAGE_VIEW = 'current_accounts_page_view';
const CURRENT_ITEMS_PAGE_VIEW = 'current_items_page_view';
const LAST_ITEMS_PAGE_VIEW = 'last_items_page_view';
const LAST_ACCOUNTS_PAGE_VIEW = 'last_accounts_page_view';
const LAST_SKINS_PAGE_VIEW = 'last_skins_page_view';
const CURRENT_BALANCE_PAGE_VIEW = 'currenc_balance_page_view';
const CURRENT_CASHBACK_PAGE_VIEW = 'currenc_cashback_page_view';
const CURRENT_PURCHASED_ORDERS_PAGE_VIEW = 'current_purchased_orders_page_view';
const CURRENT_PURCHASED_ORDER_PRODUCTS_PAGE_VIEW = 'current_purchased_order_products_page_view';
const CURRENT_SOLD_ORDERS_PAGE_VIEW = 'current_sold_orders_page_view';
const CURRENT_SOLD_ORDER_PRODUCTS_PAGE_VIEW = 'current_sold_order_products_page_view';
const CURRENT_BLOGS_PAGE_VIEW = 'current_blogs_page_view';
const CURRENT_TICKETS_PAGE_VIEW = 'current_tickets_page_view';
const DRAFT_MESSAGE = 'draft_message';
const DRAFT_TITLE = 'draft_title';
const DRAFT_CREATED_DATE = 'draft_created_date';
const DRAFT_UPDATED_DATE = 'draft_updated_date';
const SELL_FORM_ACCOUNT_NAME = 'sell_form_account_name';
const SELL_FORM_AGE_18 = 'sell_form_age_18';
const SELL_FORM_ORIGINAL_OWNER = 'sell_form_original_owner';
const SELL_FORM_ORIGINAL_OWNER_LABEL = 'sell_form_original_owner_label';
const SELL_FORM_CONTACT_EMAIL_ADDRESS = 'sell_form_contact_email_address';
const SELL_FORM_EXPECTED_PRICE = 'sell_form_expected_price';
const SELL_FORM_PAYPAL_EMAIL_ADDRESS = 'sell_form_paypal_email_address';
const SELL_FORM_PAYMENT_FREQUENCY = 'sell_form_payment_frequency';
const SELL_FORM_COUNTRY_CREATION = 'sell_form_country_creation';
const SELL_FORM_OFFSITE_REPUTATION = 'sell_form_offsite_reputation';
const SELL_FORM_ADDITIONAL_INFORMATION = 'sell_form_additional_information';
const SELL_FORM_PAYMENT_METHOD = 'sell_form_payment_method';
const SELL_FORM_QUANTITY = 'sell_form_quantity';
const CURRENT_SKINS_PAGE_VIEW = 'current_skins_page_view';
const CURRENT_COUPONS_PAGE_VIEW = 'current_coupons_page_view';
const PROFILE_PATH = 'profile_path';
const ORDER_DATA_KEY = 'order_data';
const ACTIVE_REFERRAL_CODE_KEY = 'active_referral_code';
const LAST_DELETED_PRODUCT_CATEGORY = 'last_deleted_category';
const LOCAL_ACTIVE_AFFILIATE_CODE_KEY = 'local_active_affiliate_code';
export let SessionService = (_dec = inject(ApiService, ReferralService, EventAggregator, CurrencyService, UserDocumentService, BlacklistService, CustomerService, ThousandSeparatorValueConverter, Helper, SubscriptionService, ScriptService, SiteSettingService, HttpClient, ToastService), _dec(_class = class SessionService {
  /**
   * @constructor
   * @param {ApiService} apiService
   * @param {ReferralService} referralService
   * @param {EventAggregator} eventAggregator
   * @param {CurrencyService} currencyService
   * @param {UserDocumentService} userDocumentService
   * @param {BlacklistService} blacklistService
   * @param {CustomerService} customerService
   * @param {ThousandSeparatorValueConverter} thousandSeparatorValueConverter
   * @param {Helper} helper
   * @param {SubscriptionService} subscriptionService
   * @param {ScriptService} scriptService
   * @param {SiteSettingService} siteSettingService
   * @param {HttpClient} http
   * @param {ToastService} toastService
   */
  constructor(apiService, referralService, eventAggregator, currencyService, userDocumentService, blacklistService, customerService, thousandSeparatorValueConverter, helper, subscriptionService, scriptService, siteSettingService, http, toastService) {
    this.isAuthenticated = false;
    this.currentUser = null;
    this.geolocation = void 0;
    this.userBlacklist = void 0;
    this.userProxy = void 0;
    this.urlParams = void 0;
    this.intercomTimer = void 0;
    this.CURRENCY_OPTIONS = [];
    this.userCart = [];
    this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.intervalAttempts = void 0;
    this.triggeredIntercomScript = void 0;
    this.profileDataResponse = void 0;
    this.isProfileRequestPending = false;
    this.profileRequestQueue = [];
    this.saveLastPageConfig = (config, name) => window.localStorage[`last_${name}_page_config`] = JSON.stringify(config);
    this.getLastPageConfig = name => this.parseIfKey(`last_${name}_page_config`);
    this.parseIfKey = key => this.helper.parseIfExists(window.localStorage[key]);
    /**
     * @param {Product} product
     * @returns {CustomOrderProduct}
     */
    this.mapProductForSwap = product => {
      return {
        productId: product.id,
        isSell: false,
        price: product.price,
        character: product.character,
        imagePath: product.imagePath,
        serviceFullName: product.serviceFullName,
        quantity: Number.parseInt(product.quantity ?? '1'),
        productCategoryId: product.productCategoryId,
        productCategory: product.productCategory,
        isCustomOrder: Boolean(product.serviceFullName)
      };
    };
    /**
     * @param {Product} item
     * @returns {boolean}
     */
    this.handleLocalLocalOrder = item => {
      if (this.currentUser) return false;
      const updatedLocalOrder = this.updateLocalOrder(item);
      if (!updatedLocalOrder) return true;
      if (!updatedLocalOrder.skip) this.storeLocalOrder(updatedLocalOrder);
      return false;
    };
    this.checkIfUserAgentPrerender = () => navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
    this.checkIfIntercomExistsAndRemove = () => {
      const intercomFacadeBtn = document.querySelector('#intercom-facade-btn');
      if (intercomFacadeBtn) intercomFacadeBtn.remove();
      const intercomScript = document.querySelector('#intercom-script');
      if (intercomScript) intercomScript.remove();
    };
    this.apiService = apiService;
    this.referralService = referralService;
    this.eventAggregator = eventAggregator;
    this.currencyService = currencyService;
    this.userDocumentService = userDocumentService;
    this.blacklistService = blacklistService;
    this.customerService = customerService;
    this.subscriptionService = subscriptionService;
    this.thousandSeparatorValueConverter = thousandSeparatorValueConverter;
    this.helper = helper;
    this.scriptService = scriptService;
    this.siteSettingService = siteSettingService;
    this.http = http;
    this.toastService = toastService;
    this.pageConfigFunctionality();
    this.helper.getResolutions(this);
  }
  async login(data) {
    const formattedData = {
      email: data.email,
      password: data.password,
      token: data.token,
      fromLoginPage: data.fromLoginPage,
      jwtToken: data.jwtToken,
      signInOption: data.signInOption,
      baseToken: data.baseToken,
      skipChecks: data.skipChecks,
      validationCode: data.validationCode
    };
    formattedData.fingerprint = await this.siteSettingService.getDeviceInfoForUser();
    const {
      ipv4Address,
      ipv6Address
    } = (await this.helper.fetchIPsForCustomer()) ?? {};
    formattedData.ipv4Address = ipv4Address;
    formattedData.ipv6Address = ipv6Address;
    const response = await this._apiRequest('', formattedData);
    if (response) {
      if (!response.resetPassword) {
        if (response.userBlacklist?.length > 0) {
          this.userBlacklist = response.userBlacklist;
          const blackListResponse = await this.verifyIsUserInBlackList();
          if (blackListResponse) {
            return;
          }
        }
        this.isAuthenticated = true;
      }
      this.helper.handleGtagEvent('login', null, null, null, null, data?.signInOption);
      return response;
    }
  }
  getSiteDownToastFired() {
    return window.localStorage.getItem(SITE_DOWN_FIRED);
  }
  removeRateLimitToastFired() {
    return window.localStorage.removeItem(RATE_LIMIT_FIRED);
  }
  removeSiteDownToastFired() {
    return window.localStorage.removeItem(SITE_DOWN_FIRED);
  }
  setSiteDownToastFired(value) {
    window.localStorage.setItem(SITE_DOWN_FIRED, value);
  }
  getRateLimitToastFired() {
    return window.localStorage.getItem(RATE_LIMIT_FIRED);
  }
  setRateLimitToastFired(value) {
    window.localStorage.setItem(RATE_LIMIT_FIRED, value);
  }
  async getCurrencyOptions() {
    const currencies = await this.currencyService.getActiveCurrenciesByWebsite();
    this.CURRENCY_OPTIONS = [];
    if (currencies?.length) {
      for (const currency of currencies) {
        this.CURRENCY_OPTIONS.push({
          id: currency.id,
          value: currency.code,
          text: currency.code,
          displayName: currency.description,
          icon: currency.imagePath,
          currencySymbol: currency.symbol,
          isTrending: currency.isTrending,
          imagePath: currency.imagePath
        });
      }
    }
    return this.CURRENCY_OPTIONS.sort((a, b) => a.value?.toLowerCase()?.localeCompare(b.value?.toLowerCase()));
  }
  getDesiredCurrency(currencyCode) {
    return this.CURRENCY_OPTIONS.find(currency => currency.value === currencyCode);
  }
  async getProfile(forceRefresh) {
    if (forceRefresh === void 0) {
      forceRefresh = false;
    }
    if (await this.isTokenValid()) {
      if (this.currentUser && !forceRefresh) {
        return this.currentUser;
      }
      return await this.fetchProfileData(forceRefresh);
    }
  }
  async refreshProfile() {
    if (!(await this.isTokenValid())) return;
    const result = await this.fetchProfileData(true);
    this.helper.debounce(this, 'updatingUser', 'updateUserTimeout', 750, () => this.eventAggregator.publish('user-updated', {
      user: result
    }));
    return result;
  }
  async fetchProfileData(forceFetch) {
    if (forceFetch === void 0) {
      forceFetch = false;
    }
    this.currentUser = await this.helper.fetchData(this.apiService, this.getProfilePath() ? `profile?path=${this.getProfilePath()}` : 'profile', 'fetchProfileData', forceFetch);
    if (!this.currentUser) {
      return null;
    }
    if (!this.currentUser.isDeleted) {
      this.currentUser.isSubscribed = this.subscriptionService.hasSubscription(this.currentUser);
    } else {
      this.toastService.showToast(ToastType.INFO, 'Account deleted. As a result, you have been logged out. Sign-up again to log back in, or contact support.');
      await this.logout();
    }
    if (this.currentUser.userBlacklist?.length > 0) {
      this.userBlacklist = this.currentUser.userBlacklist;
      const blackListResponse = await this.verifyIsUserInBlackList();
      if (blackListResponse) {
        await this.logout();
      }
    }
    return this.currentUser;
  }
  async verifyIsUserInBlackList() {
    let message = 'BLlvl4';
    if (!this.userBlacklist) return;
    for (const row of this.userBlacklist) {
      if (row.level > 2) {
        this.closeLivechat();
      }
      if (row.level === 4) {
        message += row.category === 1 ? 'E' : row.category === 2 ? 'P' : row.category === 3 ? 'IP' : row.category === 4 ? 'C' : 'R';
        this.toastService.showToast(ToastType.ERROR, 'There was an unexpected error. Please contact customer support at support@chicksgold.com. Ref:' + message + '.');
        return true;
      }
    }
    return false;
  }
  closeLivechat() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    this.checkIfIntercomExistsAndRemove();
  }
  async initializeLivechat() {
    let intercomSettings = {};
    if (this.currentUser) {
      intercomSettings = {
        email: debug() ? `STAGING-${this.currentUser?.email?.replace('@', '_cg@')}` : this.currentUser?.email?.replace('@', '_cg@'),
        // eslint-disable-next-line camelcase
        user_id: debug() ? `S${this.currentUser?.id}` : this.currentUser?.id,
        // eslint-disable-next-line camelcase
        user_hash: this.currentUser?.liveChatHash,
        name: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
        // eslint-disable-next-line camelcase
        customer_URL: `${adminPanelUrl()}customers/${this.currentUser?.id}`
      };
      this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser.email);
      if (this.userBlacklist?.find(x => x.level === 3 || x.level === 4)) {
        this.closeLivechat();
        return;
      }
    }

    // eslint-disable-next-line camelcase
    intercomSettings.website_FROM = 'Chicks Gold Inc.';
    const globalIntercomSettings = {
      // eslint-disable-next-line camelcase
      api_base: 'https://api-iam.intercom.io',
      // eslint-disable-next-line camelcase
      app_id: 'dqgl5no3',
      ...intercomSettings
    };
    window.intercomSettings = globalIntercomSettings;
    if (!this.triggeredIntercomScript) this.scriptService.injectIntercomScript();
    if (this.intercomTimer) return;
    this.intervalAttempts = 0;
    this.intercomTimer = setInterval(async () => {
      if (window.Intercom) {
        window.Intercom('boot', globalIntercomSettings);
        const urlParams = new URLSearchParams(window.location.search);
        const openLivechat = urlParams.get('openLivechat');
        if (openLivechat === 'true') {
          window.Intercom('showNewMessage', '');
        }
        clearInterval(this.intercomTimer);
        this.intercomTimer = null;
      } else if (this.intervalAttempts > 10) {
        clearInterval(this.intercomTimer);
        this.intercomTimer = null;
      }
      this.intervalAttempts++;
    }, 1000);
  }

  /**
   * Logs out the current user by performing the following actions:
   * - Sends a delete request to the 'Logout' endpoint.
   * - Publishes an 'untrack-user' event with the current user's ID.
   * - Resets various page views to the first page.
   * - Destroys the authentication token and referrer code.
   * - Clears the current user and user cart.
   * - Sets the authentication status to false.
   * - Publishes a 'user-updated' event indicating logout.
   * - Retrieves and destroys the user cart.
   * - Clears the service queue state for 'fetchProfileData'.
   *
   * @param {User | null} user - The user object to log out, or null.
   * @returns {Promise<void>} A promise that resolves when the logout process is complete.
   */
  async logout(user) {
    if (user === void 0) {
      user = null;
    }
    try {
      await this.apiService.doDelete('Logout');
      this.eventAggregator.publish('untrack-user', {
        userId: user?.id || this.currentUser.id
      });
      this.saveAccountsCurrentPageView(1);
      this.saveItemsCurrentPageView(1);
      this.saveSkinsCurrentPageView(1);
      this.savePurchasedOrdersCurrentPageView(1);
      this.savePurchasedProductsCurrentPageView(1);
      this.saveSoldOrdersCurrentPageView(1);
      this.saveSoldProductsCurrentPageView(1);
      this.saveBlogsCurrentPageView(1);
      this.saveTicketsCurrentPageView(1);
      this.saveCouponsCurrentPageView(1);
      this.saveCashbackCurrentPageView(1);
    } finally {
      this.destroyToken();
      this.currentUser = null;
      this.userCart = null;
      this.isAuthenticated = false;
      this.eventAggregator.publish('user-updated', {
        logout: true
      });
      await this.getCart();
      await this.destroyCart();
      this.helper.clearServiceQueueState('fetchProfileData');
    }
  }
  async verifyPassword(password) {
    return await this.apiService.doPost('VerifyPassword', password);
  }
  clearSession() {
    this.destroyToken();
    this.currentUser = null;
    this.userCart = null;
    this.isAuthenticated = false;
    this.eventAggregator.publish('user-updated', {});
  }
  async getUserFirstName(email) {
    return await this.apiService.doGet(`GetUserFirstName/${email}`);
  }

  /**
   * @param {string} authorizationCode
   * @param {string} clientId
   * @param {string?} validationCode
   * @returns {Promise<{ token: string | null | undefined }>}
   */
  async validateAuthorizationCode(authorizationCode, clientId, validationCode) {
    return await this.apiService.doPost('AuthorizeCode', {
      authorizationCode,
      clientId,
      validationCode
    });
  }
  async _apiRequest(path, user) {
    return await this.apiService.doPost(path, user);
  }

  /**
   * @param {string | null} token
   */
  saveToken(token) {
    if (!token) return;
    window.localStorage[TOKEN_KEY] = token;
  }

  /**
   * @returns {string | null}
   */
  getStoredToken() {
    return window.localStorage[TOKEN_KEY];
  }

  /**
   * @returns {Promise<string|null>}
   */
  async getToken() {
    const token = this.getStoredToken();
    const isValid = await this.isTokenValid(false, token, false);
    if (!isValid && token) {
      try {
        return await this.refreshAccessToken();
      } catch (e) {
        console.error(e);
        this.destroyToken();
        return null;
      }
    }
    return token;
  }
  refreshAccessToken() {
    if (this.isRefreshing) {
      // If a refresh is already in progress, wait for it to complete
      return this.refreshTokenPromise;
    }
    this.isRefreshing = true;

    // Create a promise to manage the refresh state
    this.refreshTokenPromise = new Promise((resolve, reject) => {
      const token = this.getStoredToken();
      if (!token) {
        reject('No access token to refresh');
      }
      this.http.fetch('RefreshToken', {
        method: 'POST',
        body: json({
          token
        }),
        headers: {
          'X-Skip-Interceptor': 'true'
        }
      }).then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh token');
        }
        return response.json();
      }).then(data => {
        this.saveToken(data.token);
        this.isRefreshing = false;
        this.refreshTokenPromise = null;
        resolve(data.token);
      }).catch(err => {
        this.isRefreshing = false;
        this.refreshTokenPromise = null;
        reject(err);
      });
    });
    return this.refreshTokenPromise;
  }
  destroyToken() {
    window.localStorage.removeItem(TOKEN_KEY);
  }

  /**
   * @param {boolean} ignoreExpiration
   * @param {string | null} token
   * @param {boolean} fetchToken
   * @returns {Promise<boolean>}
   */
  async isTokenValid(ignoreExpiration, token, fetchToken) {
    if (ignoreExpiration === void 0) {
      ignoreExpiration = false;
    }
    if (token === void 0) {
      token = null;
    }
    if (fetchToken === void 0) {
      fetchToken = true;
    }
    if (fetchToken) {
      token ??= await this.getToken();
    }
    let expired = false;
    let requiresIpAuthorization = false;
    try {
      const validToken = jwtDecode(token);
      expired = Date.now() >= validToken?.exp * 1000;
      requiresIpAuthorization = Boolean(validToken?.ria);
    } catch {
      return false;
    }
    return token && token !== '' && token !== undefined && token !== 'undefined' && token !== 'null' && (!expired || ignoreExpiration) && !requiresIpAuthorization;
  }
  isLoggedIn() {
    return Boolean(this.currentUser);
  }
  async savePreviousCurrency(currency) {
    window.localStorage[PREVIOUS_CURRENCY_KEY] = currency;
  }
  async getPreviousCurrency() {
    return window.localStorage[PREVIOUS_CURRENCY_KEY] || (await this.getCurrencyByCountry());
  }
  async savePreviousPaymentMethod(currency) {
    window.localStorage[PREVIOUS_PAYMENT_METHOD_KEY] = currency;
  }
  async getPreviousPaymentMethod() {
    return window.localStorage[PREVIOUS_PAYMENT_METHOD_KEY] || (await this.getCurrencyByCountry());
  }
  async saveCurrency(currency) {
    if (!currency) return;
    await this.currencyService.getStoredCurrencyRates(currency);
    const oldCurrency = await this.getCurrency();
    window.localStorage[CURRENCY_KEY] = currency;
    this.eventAggregator.publish('currency-updated', {
      currency: currency,
      oldCurrency: oldCurrency
    });
    signalBindings('currency-changed');
  }
  async getUserProxy() {
    return this.userProxy;
  }
  getCountry() {
    return this.geolocation?.countryCode;
  }
  async isCountryValidForCartPage() {
    if (this.checkIfCountryBlocked === null || this.checkIfCountryBlocked === undefined) {
      this.checkIfCountryBlocked = await this.apiService.doGet(`CheckBlockedCountries?country=${this.geolocation?.countryCode}`);
    }
    return (await this.checkRolesForPanelAccess()) || !this.checkIfCountryBlocked;
  }
  async getState() {
    return this.geolocation?.regionName;
  }
  async getCity() {
    return this.geolocation?.city;
  }
  async getCurrency() {
    if (this.checkIfUserAgentPrerender()) return 'USD';
    return window.localStorage[CURRENCY_KEY] || (await this.getCurrencyByCountry());
  }
  async getCurrencyByCountry() {
    const country = await this.getCountry();
    if (country) {
      const options = await this.getCurrencyOptions();
      return options.find(x => x.value === countryToCurrency[country])?.value ?? 'USD';
    }
  }
  parseLanguage(language) {
    return language?.language ? language.language : language;
  }
  getLanguage() {
    return null;
  }
  async getLanguageByCountry() {
    const country = await this.getCountry();
    if (!country) return;
    return codes.findCountryLanguages(country)?.find(x => x === country.toLowerCase()) ?? codes.findCountryLanguages(country)?.[0] ?? 'en';
  }
  destroyReferralLink() {
    window.localStorage.removeItem(REFERRAL_LINK_KEY);
  }
  destroyReferrerLink() {
    window.localStorage.removeItem(REFERRER_LINK_KEY);
  }
  destroyReferrerCode() {
    window.localStorage.removeItem(REFERRER_USER_ID);
  }

  /**
   * @param {string} link
   */
  activateReferralLinkForAuth(link) {
    window.localStorage[ACTIVE_REFERRAL_LINK_KEY] = link;
  }
  removeAuthReferralLink() {
    window.localStorage.removeItem(ACTIVE_REFERRAL_LINK_KEY);
  }
  async saveReferralLink(linkName) {
    await this.referralService.submitReferralLink(linkName, '1');
    window.localStorage[REFERRAL_LINK_KEY] = linkName;
    this.activateReferralLinkForAuth(linkName);
  }
  async saveReferrerLink(linkName) {
    await this.referralService.submitReferralLink(linkName, '2');
    window.localStorage[REFERRER_LINK_KEY] = linkName;
  }
  saveReferrerCode(userId) {
    if (userId) {
      localStorage.setItem(REFERRER_USER_ID, userId);
    } else {
      localStorage.removeItem(REFERRER_USER_ID);
    }
  }
  getReferralLink() {
    return window.localStorage[REFERRAL_LINK_KEY];
  }
  getReferrerLink() {
    return window.localStorage[REFERRER_LINK_KEY];
  }
  getReferrerCode() {
    return window.localStorage[REFERRER_USER_ID];
  }
  setPlatformLinkCookie(platformLink, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = '; expires=' + date.toGMTString();
    document.cookie = platformLink + '=' + value + expires + ';path=/';
  }
  getPlatformLinkCookie(name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || undefined;
  }
  setLookbackAndEnhanceLookbackCookie(name) {
    document.cookie = `${name}=true; expires=2147483647; path=/`;
  }
  removeCookie(name, deleteEmpty) {
    if (deleteEmpty === void 0) {
      deleteEmpty = false;
    }
    if (Array.isArray(name)) {
      if (deleteEmpty) {
        name.forEach(x => {
          document.cookie?.split('; ')?.forEach(row => {
            const keyValue = row.split('=');
            if (keyValue[0].includes(x) && keyValue[1] === '') {
              document.cookie = `${x}= ; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
            }
          });
        });
        return;
      }
      name.forEach(x => document.cookie = `${x}= ; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`);
      return;
    }
    if (deleteEmpty) {
      document.cookie?.split('; ')?.find(row => {
        const keyValue = row.split('=');
        if (keyValue[0] === name && keyValue[1] === '') {
          document.cookie = `${name}= ; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
        }
      });
      return;
    }
    document.cookie = `${name}= ; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
  }
  savePurchased(purchased) {
    window.localStorage[PURCHASED] = purchased;
  }
  getPurchased() {
    return window.localStorage[PURCHASED];
  }
  destroyPurchased() {
    window.localStorage.removeItem(PURCHASED);
  }
  saveAdminView(bool) {
    window.localStorage[ADMIN_VIEW] = bool;
    this.eventAggregator.publish('admin-view-updated', {
      bool: bool
    });
  }
  getAdminView() {
    return window.localStorage[ADMIN_VIEW] === 'true';
  }
  saveGameCharacter(game, characterName) {
    const gameCharacters = window.localStorage[GAME_CHARACTER];
    if (gameCharacters) {
      let games = JSON.parse(gameCharacters);
      if (games.length === 0) {
        games = {};
      }
      games[game] = characterName;
      window.localStorage[GAME_CHARACTER] = JSON.stringify(games);
    } else {
      const games = {};
      games[game] = characterName;
      window.localStorage[GAME_CHARACTER] = JSON.stringify(games);
    }
  }
  getGameCharacter(game) {
    const gameCharacters = window.localStorage[GAME_CHARACTER];
    if (gameCharacters) {
      const parsedJson = JSON.parse(gameCharacters);
      if (parsedJson[game?.toString()]) {
        return parsedJson[game?.toString()];
      }
    }
    return null;
  }
  async canAccessAdminPanel() {
    if (this.currentUser) {
      return await this.checkRolesForPanelAccess();
    } else {
      this.currentUser = await this.getProfile();
      if (!this.currentUser) {
        return false;
      }
      return await this.checkRolesForPanelAccess();
    }
  }
  async checkRolesForPanelAccess() {
    try {
      const token = await this.getToken();
      if (!token) return;
      return jwtDecode(token)?.admin;
    } catch {
      return;
    }
  }
  saveAccountsCurrentPageView(page) {
    window.localStorage[CURRENT_ACCOUNTS_PAGE_VIEW] = page;
  }
  getAccountsCurrentPageView() {
    const currentAccountPageView = window.localStorage[CURRENT_ACCOUNTS_PAGE_VIEW];
    if (currentAccountPageView) {
      return JSON.parse(currentAccountPageView);
    }
  }
  saveLastAccountsPageView(game) {
    window.localStorage[LAST_ACCOUNTS_PAGE_VIEW] = game;
  }
  getLastAccountsPageView() {
    return window.localStorage[LAST_ACCOUNTS_PAGE_VIEW];
  }
  saveItemsCurrentPageView(page) {
    window.localStorage[CURRENT_ITEMS_PAGE_VIEW] = page;
  }
  getItemsCurrentPageView() {
    const currentItemsPageView = window.localStorage[CURRENT_ITEMS_PAGE_VIEW];
    if (currentItemsPageView) {
      return JSON.parse(currentItemsPageView);
    }
  }
  saveLastItemsPageView(game) {
    window.localStorage[LAST_ITEMS_PAGE_VIEW] = game;
  }
  getLastItemsPageView() {
    return window.localStorage[LAST_ITEMS_PAGE_VIEW];
  }
  saveSkinsCurrentPageView(page) {
    window.localStorage[CURRENT_SKINS_PAGE_VIEW] = page;
  }
  pageConfigFunctionality() {
    ['Items', 'Accounts', 'Skins', 'Gifts'].forEach(x => {
      this[`saveLast${x}PageConfig`] = config => this.saveLastPageConfig(config, x.toLowerCase());
      this[`getLast${x}PageConfig`] = () => this.getLastPageConfig(x.toLowerCase());
    });
  }
  getSkinsCurrentPageView() {
    const currentSkinsPageView = window.localStorage[CURRENT_SKINS_PAGE_VIEW];
    if (currentSkinsPageView) {
      return JSON.parse(currentSkinsPageView);
    }
  }
  saveLastSkinsPageView(game) {
    window.localStorage[LAST_SKINS_PAGE_VIEW] = game;
  }
  getLastSkinsPageView() {
    return window.localStorage[LAST_SKINS_PAGE_VIEW];
  }
  saveBalanceCurrentPageView(page) {
    window.localStorage[CURRENT_BALANCE_PAGE_VIEW] = page;
  }
  getBalanceCurrentPageView() {
    const currentBalancePageView = window.localStorage[CURRENT_BALANCE_PAGE_VIEW];
    if (currentBalancePageView) return JSON.parse(currentBalancePageView);
  }
  saveCashbackCurrentPageView(page) {
    window.localStorage[CURRENT_CASHBACK_PAGE_VIEW] = page;
  }
  getCashbackCurrentPageView() {
    const currentyCashbackPageView = window.localStorage[CURRENT_CASHBACK_PAGE_VIEW];
    if (currentyCashbackPageView) return JSON.parse(currentyCashbackPageView);
  }
  savePurchasedOrdersCurrentPageView(page) {
    window.localStorage[CURRENT_PURCHASED_ORDERS_PAGE_VIEW] = page;
  }
  getPurchasedOrdersCurrentPageView() {
    const currentOrdersPageView = window.localStorage[CURRENT_PURCHASED_ORDERS_PAGE_VIEW];
    if (currentOrdersPageView) {
      return JSON.parse(currentOrdersPageView);
    }
  }
  saveSoldOrdersCurrentPageView(page) {
    window.localStorage[CURRENT_SOLD_ORDERS_PAGE_VIEW] = page;
  }
  getSoldOrdersCurrentPageView() {
    const currentOrdersPageView = window.localStorage[CURRENT_SOLD_ORDERS_PAGE_VIEW];
    if (currentOrdersPageView) {
      return JSON.parse(currentOrdersPageView);
    }
  }
  savePurchasedProductsCurrentPageView(page) {
    window.localStorage[CURRENT_PURCHASED_ORDER_PRODUCTS_PAGE_VIEW] = page;
  }
  getPurchasedProductsCurrentPageView() {
    const currentOrderProductsPageView = window.localStorage[CURRENT_PURCHASED_ORDER_PRODUCTS_PAGE_VIEW];
    if (currentOrderProductsPageView) return JSON.parse(currentOrderProductsPageView);
  }
  saveSoldProductsCurrentPageView(page) {
    window.localStorage[CURRENT_SOLD_ORDER_PRODUCTS_PAGE_VIEW] = page;
  }
  getSoldProductsCurrentPageView() {
    const currentOrderProductsPageView = window.localStorage[CURRENT_SOLD_ORDER_PRODUCTS_PAGE_VIEW];
    if (currentOrderProductsPageView) return JSON.parse(currentOrderProductsPageView);
  }
  saveTicketsCurrentPageView(page) {
    window.localStorage[CURRENT_TICKETS_PAGE_VIEW] = page;
  }
  getTicketsCurrentPageView() {
    const currentTicketsPageView = window.localStorage[CURRENT_TICKETS_PAGE_VIEW];
    if (currentTicketsPageView) {
      return JSON.parse(currentTicketsPageView);
    }
  }
  saveBlogsCurrentPageView(page) {
    window.localStorage[CURRENT_BLOGS_PAGE_VIEW] = page;
  }
  getBlogsCurrentPageView() {
    const currentBlogsPageView = window.localStorage[CURRENT_BLOGS_PAGE_VIEW];
    if (currentBlogsPageView) {
      return JSON.parse(currentBlogsPageView);
    }
  }
  saveCouponsCurrentPageView(page) {
    window.localStorage[CURRENT_COUPONS_PAGE_VIEW] = page;
  }
  getCouponsCurrentPageView() {
    const currentCouponsPageView = window.localStorage[CURRENT_COUPONS_PAGE_VIEW];
    if (currentCouponsPageView) {
      return JSON.parse(currentCouponsPageView);
    }
  }
  saveEmailInReview(bool) {
    window.localStorage[EMAIL_IN_REVIEW_KEY] = bool;
  }
  getEmailInReview() {
    const emailInReview = window.localStorage[EMAIL_IN_REVIEW_KEY];
    if (emailInReview) {
      return JSON.parse(emailInReview);
    }
  }
  deleteByUserIdAndCategoryId(userId, categoryId) {
    this.userDocumentService.deleteByUserIdAndCategoryId(userId, categoryId);
  }
  async getIdVerificationInReview(id) {
    const idVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 1);
    if (idVerificationInReview) {
      return idVerificationInReview;
    }
  }
  async getAddressVerificationInReview(id) {
    const addressVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 2);
    if (addressVerificationInReview) {
      return addressVerificationInReview;
    }
  }
  async getSelfieVerificationInReview(id) {
    const selfieVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 3);
    if (selfieVerificationInReview) {
      return selfieVerificationInReview;
    }
  }
  async getCart(cart, forceFetch, local) {
    if (forceFetch === void 0) {
      forceFetch = false;
    }
    if (local === void 0) {
      local = false;
    }
    if (forceFetch) this.currentUser = await this.getProfile(true);
    if (this.currentUser && !local) {
      if (cart && !forceFetch) this.userCart = cart;
      if (this.userCart?.length && !forceFetch) {
        this.userCart.forEach((e, index) => {
          this.userCart[index].tempQuantity = this.helper.convertNumberWithoutComma(e.tempQuantity ?? e.quantity, 'int');
          this.userCart[index].quantity = this.width > this.desktop ? this.thousandSeparatorValueConverter.toView(e.quantity) : this.helper.convertNumberWithoutComma(e.quantity, 'int');
          this.userCart[index].selectedQuantity = e.quantity;
          this.userCart[index].userCartId = e.userCartId;
          if (e.name.includes('Subscription') && this.currentUser.hasFreeTrial) {
            this.userCart[index].quantity = 1;
            this.userCart[index].price = 0;
            this.userCart[index].hasFreeTrial = true;
          }
        });
        return this.userCart;
      }
      this.userCart = await this.customerService.getUserCartProducts(forceFetch);
      this.userCart.forEach((e, index) => {
        this.userCart[index].productId = e.id;
        this.userCart[index].cartItemId = index + 1;
        this.userCart[index].tempQuantity = this.helper.convertNumberWithoutComma(e.tempQuantity ?? e.quantity, 'int');
        this.userCart[index].quantity = this.width > this.desktop ? this.thousandSeparatorValueConverter.toView(e.quantity) : this.helper.convertNumberWithoutComma(e.quantity, 'int');
        this.userCart[index].selectedQuantity = e.quantity;
        this.userCart[index].servicePricing = e.servicePricingCart;
        this.userCart[index].orderId = e.orderId;
        this.userCart[index].userCartId = e.userCartId;
        this.userCart[index].productFields = e.productFields;
        if (e.name.includes('Subscription') && this.currentUser.hasFreeTrial) {
          this.userCart[index].quantity = 1;
          this.userCart[index].price = 0;
          this.userCart[index].hasFreeTrial = true;
        }
      });
      return this.userCart;
    } else {
      const stringCart = window.localStorage[CART_KEY];
      if (stringCart && stringCart !== '[]' && stringCart !== '') {
        const cartItems = JSON.parse(stringCart);
        cartItems.forEach((e, index) => {
          cartItems[index].tempQuantity = this.helper.convertNumberWithoutComma(e.tempQuantity ?? e.quantity, 'int');
          if (e.name.includes('Subscription')) {
            cartItems[index].quantity = 1;
            cartItems[index].price = 0;
            cartItems[index].hasFreeTrial = true;
          }
        });
        return cartItems;
      } else if (stringCart) {
        this.destroyCart();
      }
    }
  }
  async checkIfProductInCart(productId) {
    const cart = await this.getCart();
    return cart?.find(x => x.id === parseInt(productId));
  }
  async saveCart(cart, skipEvent, index, updateWithoutRefresh, cartId, cartItem, loggingIn, updateCart, triggerOutOfStockToast, handleOutOfStock, fromRefresh, updateOrder) {
    if (fromRefresh === void 0) {
      fromRefresh = false;
    }
    if (updateOrder === void 0) {
      updateOrder = true;
    }
    if (!skipEvent) {
      this.eventAggregator.publish('cart-updated', {
        cart: cart,
        index: index,
        updateWithoutRefresh: updateWithoutRefresh,
        triggerOutOfStockToast: triggerOutOfStockToast,
        handleOutOfStock: handleOutOfStock
      });
    }
    const orderId = cart.find(x => x.orderId)?.orderId;
    let destroying = false;
    if (this.currentUser && (!skipEvent || skipEvent && cartItem)) {
      if (cartId) {
        await this.customerService.destroyUserCartProducts(cartId);
        destroying = true;
      } else {
        const response = await this.customerService.storeUserCartProducts(cartItem ?? cart, this.currentUser?.id, loggingIn, updateCart, orderId, updateOrder);
        cart.forEach(e => {
          e.quantity = this.width > this.desktop ? this.thousandSeparatorValueConverter.toView(e.quantity) : this.helper.convertNumberWithoutComma(e.quantity, 'int');
          e.selectedQuantity = e.quantity;
        });
        if (response && cartItem) {
          const checkItem = Array.isArray(cartItem) ? cartItem[0] : cartItem;
          const productCartIndex = cart.findIndex(x => x.id === checkItem.id || x.userCartId === checkItem?.UserCartId || x.product?.productId === checkItem.id);
          if (cart[productCartIndex]) cart[productCartIndex].userCartId = response;
        }
      }
    }
    if (!fromRefresh && !destroying && this.currentUser) this.customerService.onCartUpdated();
    window.localStorage[CART_KEY] = JSON.stringify(cart);
    this.userCart = cart.copy();
  }
  async destroyCart() {
    if (this.currentUser) {
      await this.customerService.destroyUserCartProducts();
    }
    window.localStorage.removeItem(CART_KEY);
    this.userCart = null;
    this.eventAggregator.publish('cart-updated', {
      cart: []
    });
  }
  async updateCartAfterPurchase() {
    if (this.currentUser) {
      await this.customerService.updateCartAfterPurchase();
    }
  }

  /**
   * @param {boolean} bypass
   * @returns {CustomOrderRequest | null}
   */
  getLocalOrder(bypass) {
    if (bypass === void 0) {
      bypass = false;
    }
    if (!this.currentUser && !bypass) return null;
    const data = localStorage.getItem(ORDER_DATA_KEY);
    return data ? JSON.parse(data) : null;
  }

  /**
   * @param {CustomOrderRequest} orderData
   */
  storeLocalOrder(orderData) {
    if (this.currentUser) return;
    localStorage.setItem(ORDER_DATA_KEY, JSON.stringify(orderData));
  }
  clearLocalOrder() {
    localStorage.removeItem(ORDER_DATA_KEY);
  }
  /**
   * @param {Product} product
   * @returns {CustomOrderRequest | null} - Returns the updated order or null if an invalid product was found.
   */
  updateLocalOrder(product) {
    /**
     * @type {CustomOrderRequest | null}
     */
    const savedOrder = this.getLocalOrder(true);
    if (!savedOrder) return {
      skip: true
    };

    /**
     * @type {CustomOrderProduct | null}
    */
    const oldOrderProduct = savedOrder.products.find(x => x.productId === product.id && this.helper.propertiesEqual(product.serviceFullName, x.serviceFullName));
    if (!oldOrderProduct) {
      savedOrder.products.push(this.mapProductForSwap(product));
      return savedOrder;
    }
    if (!this.helper.propertiesEqual(oldOrderProduct.isSell, product.isSell)) {
      this.toastService.showToast(ToastType.ERROR, 'Cannot swap the same product. One or more products already included in the cart with a different swap category.');
      return null;
    }
    if (product.serviceFullName) {
      this.toastService.showToast(ToastType.ERROR, `${product.serviceFullName} already in the cart. Please remove it from your order or from the cart to continue.`);
      return null;
    }
    if (oldOrderProduct.quantity === Number.parseInt(product.quantity ?? '1')) return savedOrder;
    savedOrder.products = savedOrder.products.map(x => {
      if (x.productId === product.productId) return this.mapProductForSwap(product);
      return x;
    });
    return savedOrder;
  }
  async storeCartItem(item, replaceQuantity) {
    if (replaceQuantity === void 0) {
      replaceQuantity = false;
    }
    if (item.salePrice) {
      item.price = item.salePrice;
    }
    if (item.productCategory?.name === 'Accounts') {
      item.character = 'Character Name';
    }
    if (item.game?.shortName === 'SUBSCRIPTION') {
      item.quantity = item.tempQuantity = 1;
    }
    let cart = await this.getCart(null, true);
    item.cartItemId = cart?.length ? cart.length + 1 : 1;
    if (this.currentUser) {
      item.character = item.character ? item.character : null;
      if (item.productId) {
        item.id = item.productId;
      }
    }
    await Promise.all([this.helper.handleGtagEvent('add_to_cart', item, 'USD', item.price), this.helper.handleFacebookPixelEvent('AddToCart', item, 'USD', item.price), this.helper.handleRedditEvent('AddToCart', item, 'USD', item.price)]);
    if (!cart) {
      await this.saveCart([item]);
      return;
    }
    for (const cartItem of cart) {
      if (!this.shouldProductSaveCart(cartItem, item)) continue;
      if (this.handleLocalLocalOrder(item)) return;
      const [itemQuantity, cartItemQuantity] = [item.quantity, cartItem.quantity].map(x => this.helper.convertNumberWithoutComma(x, 'float'));
      cartItem.selectedQuantity = cartItem.quantity = replaceQuantity ? itemQuantity : itemQuantity + cartItemQuantity;
      cartItem.price = item.price;
      return await this.saveCart(cart, true, null, null, null, [cartItem]);
    }
    if (this.handleLocalLocalOrder(item)) return;
    cart.push(item);
    if (item.name.includes('Chicks VIP')) {
      cart = cart.filter(i => !(i.name.includes('Chicks VIP') && i.id !== item.id));
    }
    await this.saveCart(cart, null, null, null, null, [item]);
  }
  shouldProductSaveCart(cartItem, item) {
    return (cartItem.productId === parseInt(item.productId) || cartItem.id === parseInt(item.id)) && cartItem.character === item.character && (!cartItem.serviceFullName || cartItem.serviceFullName === item.serviceFullName);
  }
  async updateCartItem(item, skipEvent, fromAttached) {
    if (fromAttached === void 0) {
      fromAttached = false;
    }
    const cart = await this.getCart();
    if (cart) {
      for (const cartItem of cart) {
        if (cartItem.id === item.id && cartItem.name === item.name && cartItem.cartItemId === item.cartItemId) {
          cartItem.character = item.character;
          cartItem.quantity = this.thousandSeparatorValueConverter.toView(item.quantity.toString());
          cartItem.selectedQuantity = parseFloat(item.quantity);
          cartItem.insuranceId = item.insuranceId;
          cartItem.purchasedInsurance = item.purchasedInsurance;
          cartItem.deliveryMethod = item.deliveryMethod;
          cartItem.deliveryMethodId = item.deliveryMethod?.id;
          cartItem.userCartId = item.userCartId;
          return await this.saveCart(cart, skipEvent, null, null, null, cartItem, null, true, null, null, fromAttached);
        }
      }
    }
  }

  /**
   * @param {Product} product
   */
  deleteFromLocalOrder(product) {
    const localOrder = this.getLocalOrder(true);
    if (!localOrder) return;
    const index = localOrder.products.findIndex(x => {
      if (product.productId) return x.productId === product.productId && x.serviceFullName === product.serviceFullName;
      return product.id && product.id === x.productId && x.serviceFullName === product.serviceFullName;
    });
    if (index === -1) return;
    localOrder.products.splice(index, 1);
    this.storeLocalOrder(localOrder);
  }
  async deleteCartItem(product) {
    const cart = await this.getCart();
    let index;
    let cartId;
    if (product.cartItemId && cart.every(x => x.cartItemId) && cart.some(x => x.cartItemId === product.cartItemId)) {
      index = cart.findIndex(i => i.cartItemId === product.cartItemId);
    } else if (product.id) {
      index = cart.findIndex(i => i.id === product.id);
    } else if (product.productId) {
      index = cart.findIndex(i => i.productId === product.productId);
    } else {
      index = cart.findIndex(i => i.name === product.name);
    }
    await this.helper.handleGtagEvent('remove_from_cart', product, 'USD', product.price);
    if (index !== -1) {
      if (this.currentUser) {
        cartId = cart[index]?.userCartId;
      }
      cart.splice(index, 1);
    }
    await this.saveCart(cart, false, index, false, cartId);
    if (cart.length < 1) {
      window.localStorage[CART_KEY] = JSON.stringify([]);
      this.clearLocalOrder();
      return true;
    }
  }
  async refreshCart(currencyUsed, orderAdClicks, updateCartItems, triggerToastServices) {
    const cart = await this.getCart(null, true);
    const newCart = cart.map(x => Object.assign({}, x));
    newCart.forEach((item, index) => {
      if (!item.isDeleted) return;
      newCart.splice(index, 1);
    });
    if (newCart) {
      const data = await this.customerService.refreshCart(newCart, currencyUsed, orderAdClicks);
      if (!data.products?.length && !data.services?.length) return;
      cart.forEach(async (item, index) => {
        const currentElement = data.products?.find(x => x.productId === item.productId && (item.character ? x.character === item.character : true) && (item.serviceFullName ? x.serviceFullName === item.serviceFullName : true));
        if (!currentElement) return;
        const isService = Boolean(item?.servicePricing) || item?.isService || item?.productCategory === 'Services';
        const isStaticService = isService && currentElement?.product?.name?.toLowerCase() !== 'dynamic service';
        if (item.serviceFullName) item.updatedDate = currentElement.product.updatedDate;
        if (item.price !== currentElement.price && !isStaticService) item.price = currentElement.price;
        if (item.character !== currentElement.character) item.character = currentElement.character;
        if (currentElement.product?.deliveryMethods?.length && !this.helper.checkIfTwoArraysOfObjectsEqual(item.deliveryMethods, currentElement.product?.deliveryMethods)) item.deliveryMethods = currentElement.product.deliveryMethods;
        const isLastIteration = index === cart.length - 1;
        if (updateCartItems) await this.saveCart(cart, true, null, null, null, item, null, true, null, null, true, isLastIteration);
      });
    }
    this.eventAggregator.publish('cart-updated', {
      cart: cart,
      triggerToastServices: triggerToastServices
    });
  }

  /**
   * @param {Product | null} product
   * @returns {Promise<boolean>}
   */
  async validateCharacterName(product) {
    const cart = await this.getCart();
    return !cart?.find(x => product?.isService && x.name === product?.name && x.character === product?.character && (x.cartItemId !== product?.cartItemId || x.userCartId !== product?.userCartId));
  }
  async checkIfUserByPaymentMethodInBlackList(selectedPaymentMethod, forceFetchBlacklist) {
    const result = {
      selectedPaymentMethod: false,
      paymentMethods: false,
      filterPaymentMethods: false
    };
    if (!this.currentUser) {
      this.currentUser = await this.getProfile();
    }
    if (this.currentUser) {
      this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser.email, forceFetchBlacklist);
      if (this.userBlacklist?.some(x => this.helper.includesNumber(x.level, [3, 4]) && (this.helper.includesNumber(x.category, [1, 2, 3, 6, 7, 8, 9, 10]) || x.addByVeriffGroup && x.veriffGroupActive))) {
        result.paymentMethods = true;
        result.selectedPaymentMethod = true;
        this.closeLivechat();
        return result;
      }
      if (this.userBlacklist?.some(x => x.level === 2 && (this.helper.includesNumber(x.category, [1, 2, 3, 6, 7, 8, 9, 10]) || x.addByVeriffGroup && x.veriffGroupActive))) {
        result.filterPaymentMethods = true;
        if (selectedPaymentMethod && !['crypto', 'coinpayments', 'btcpay', 'bitcart'].includes(selectedPaymentMethod?.paymentMethod.reference)) {
          result.selectedPaymentMethod = true;
        }
      }
    }
    await this.initializeLivechat();
    return result;
  }
  saveDraftMessage(message) {
    window.localStorage[DRAFT_MESSAGE] = message;
  }
  getDraftMessage() {
    return window.localStorage[DRAFT_MESSAGE];
  }
  destroyDraftMessage() {
    window.localStorage.removeItem(DRAFT_MESSAGE);
  }
  saveDraftTitle(title) {
    window.localStorage[DRAFT_TITLE] = title;
  }
  getDraftTitle() {
    return window.localStorage[DRAFT_TITLE];
  }
  destroyDraftTitle() {
    window.localStorage.removeItem(DRAFT_TITLE);
  }
  saveDraftCreatedDate(date) {
    window.localStorage[DRAFT_CREATED_DATE] = date;
  }
  getDraftCreatedDate() {
    return window.localStorage[DRAFT_CREATED_DATE];
  }
  destroyDraftCreatedDate() {
    window.localStorage.removeItem(DRAFT_CREATED_DATE);
  }
  saveDraftUpdatedDate(date) {
    window.localStorage[DRAFT_UPDATED_DATE] = date;
  }
  getDraftUpdatedDate() {
    return window.localStorage[DRAFT_UPDATED_DATE];
  }
  destroyDraftUpdatedDate() {
    window.localStorage.removeItem(DRAFT_UPDATED_DATE);
  }
  saveProfilePath(profilePath) {
    window.localStorage[PROFILE_PATH] = profilePath;
  }
  getProfilePath() {
    return window.localStorage[PROFILE_PATH];
  }
  destroyProfilePath() {
    window.localStorage.removeItem(PROFILE_PATH);
  }
  async checkFreeTrialAndGetUser() {
    await this.refreshProfile();
    if (!this.currentUser) return;
    if (this.currentUser.hasFreeTrial) {
      const ft = await this.subscriptionService.hasChicksVipFreeTrial(this.currentUser);
      this.currentUser.hasFreeTrial = ft;
    }
    return this.currentUser;
  }
  saveSellFormAccountName(accountName) {
    window.localStorage[SELL_FORM_ACCOUNT_NAME] = accountName;
  }
  getSellFormAccountName() {
    return window.localStorage[SELL_FORM_ACCOUNT_NAME];
  }
  destroySellFormAccountName() {
    window.localStorage.removeItem(SELL_FORM_ACCOUNT_NAME);
  }
  saveSellFormOverEighteen(overEighteen) {
    window.localStorage[SELL_FORM_AGE_18] = overEighteen;
  }
  getSellFormOverEighteen() {
    return window.localStorage[SELL_FORM_AGE_18] ? window.localStorage[SELL_FORM_AGE_18] === 'true' : window.localStorage[SELL_FORM_AGE_18];
  }
  destroySellFormOverEighteen() {
    window.localStorage.removeItem(SELL_FORM_AGE_18);
  }
  saveSellFormOriginalOwner(originalOwner) {
    window.localStorage[SELL_FORM_ORIGINAL_OWNER] = originalOwner;
  }
  getSellFormOriginalOwner() {
    return window.localStorage[SELL_FORM_ORIGINAL_OWNER] ? window.localStorage[SELL_FORM_ORIGINAL_OWNER] === 'true' : window.localStorage[SELL_FORM_ORIGINAL_OWNER];
  }
  destroySellFormOriginalOwner() {
    window.localStorage.removeItem(SELL_FORM_ORIGINAL_OWNER);
  }
  saveSellFormOriginalOwnerLabel(originalOwnerLabel) {
    window.localStorage[SELL_FORM_ORIGINAL_OWNER_LABEL] = originalOwnerLabel;
  }
  getSellFormOriginalOwnerLabel() {
    return window.localStorage[SELL_FORM_ORIGINAL_OWNER_LABEL];
  }
  destroySellFormOriginalOwnerLabel() {
    window.localStorage.removeItem(SELL_FORM_ORIGINAL_OWNER_LABEL);
  }
  saveSellFormContactEmailAddress(contactEmailAddress) {
    window.localStorage[SELL_FORM_CONTACT_EMAIL_ADDRESS] = contactEmailAddress;
  }
  getSellFormContactEmailAddress() {
    return window.localStorage[SELL_FORM_CONTACT_EMAIL_ADDRESS];
  }
  destroySellFormContactEmailAddress() {
    window.localStorage.removeItem(SELL_FORM_CONTACT_EMAIL_ADDRESS);
  }
  saveSellFormExpectedPrice(expectedPrice) {
    window.localStorage[SELL_FORM_EXPECTED_PRICE] = expectedPrice;
  }
  getSellFormExpectedPrice() {
    return window.localStorage[SELL_FORM_EXPECTED_PRICE];
  }
  destroySellFormExpectedPrice() {
    window.localStorage.removeItem(SELL_FORM_EXPECTED_PRICE);
  }
  saveSellFormPaypalEmailAddress(paypalEmailAddress) {
    window.localStorage[SELL_FORM_PAYPAL_EMAIL_ADDRESS] = paypalEmailAddress;
  }
  getSellFormPaypalEmailAddress() {
    return window.localStorage[SELL_FORM_PAYPAL_EMAIL_ADDRESS];
  }
  destroySellFormPaypalEmailAddress() {
    window.localStorage.removeItem(SELL_FORM_PAYPAL_EMAIL_ADDRESS);
  }
  saveSellFormPaymentFrequency(paymentFrequency) {
    window.localStorage[SELL_FORM_PAYMENT_FREQUENCY] = JSON.stringify(paymentFrequency);
  }
  getSellFormPaymentFrequency() {
    const stringPaymentFrequency = window.localStorage[SELL_FORM_PAYMENT_FREQUENCY];
    return stringPaymentFrequency && stringPaymentFrequency !== '[]' && stringPaymentFrequency !== '' ? JSON.parse(stringPaymentFrequency) : [];
  }
  destroySellFormPaymentFrequency() {
    window.localStorage.removeItem(SELL_FORM_PAYMENT_FREQUENCY);
  }
  saveSellFormCountryCreation(countryCreation) {
    window.localStorage[SELL_FORM_COUNTRY_CREATION] = JSON.stringify(countryCreation);
  }
  getSellFormCountryCreation() {
    const countryCreation = window.localStorage[SELL_FORM_COUNTRY_CREATION];
    return countryCreation && countryCreation !== '[]' && countryCreation !== '' ? JSON.parse(countryCreation) : [];
  }
  destroySellFormCountryCreation() {
    window.localStorage.removeItem(SELL_FORM_COUNTRY_CREATION);
  }
  saveSellFormOffsiteReputation(offsiteReputation) {
    window.localStorage[SELL_FORM_OFFSITE_REPUTATION] = offsiteReputation;
  }
  getSellFormOffsiteReputation() {
    return window.localStorage[SELL_FORM_OFFSITE_REPUTATION];
  }
  destroySellFormOffsiteReputation() {
    window.localStorage.removeItem(SELL_FORM_OFFSITE_REPUTATION);
  }
  saveSellFormAdditionalInformation(additionalInformation) {
    window.localStorage[SELL_FORM_ADDITIONAL_INFORMATION] = additionalInformation;
  }
  getSellFormAdditionalInformation() {
    return window.localStorage[SELL_FORM_ADDITIONAL_INFORMATION];
  }
  destroySellFormAdditionalInformation() {
    window.localStorage.removeItem(SELL_FORM_ADDITIONAL_INFORMATION);
  }
  saveSellFormQuantity(quantity) {
    localStorage.setItem(SELL_FORM_QUANTITY, quantity);
  }
  getSellFormQuantity() {
    return localStorage.getItem(SELL_FORM_QUANTITY);
  }
  destroySellFormQuantity() {
    localStorage.removeItem(SELL_FORM_QUANTITY);
  }
  saveSellFormPaymentMethod(paymentMethod) {
    localStorage.setItem(SELL_FORM_PAYMENT_METHOD, JSON.stringify(paymentMethod));
  }
  getSellFormPaymentMethod() {
    return JSON.parse(localStorage.getItem(SELL_FORM_PAYMENT_METHOD));
  }
  destroySellFormPaymentMethod() {
    localStorage.removeItem(SELL_FORM_PAYMENT_METHOD);
  }
  async checkVeriffBlacklistUser(update) {
    if (update === void 0) {
      update = false;
    }
    if (!this.currentUser) return false;
    this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser.email, update);
    return this.userBlacklist?.some(x => this.helper.includesNumber(x.level, [1, 2]));
  }

  /**
   * Saves the active referral code to local storage.
   *
   * @param {string} code - The referral code to be saved.
   * @returns {void} This function does not return a value.
   */
  saveActiveReferralCode(code) {
    window.localStorage[ACTIVE_REFERRAL_CODE_KEY] = code;
  }

  /**
   * Retrieves the active referral code from local storage.
   *
   * @returns {string | null} The active referral code if it exists in local storage, or null if it doesn't.
   */
  getActiveReferralCode() {
    return window.localStorage[ACTIVE_REFERRAL_CODE_KEY] || null;
  }
  destroyActiveReferralCode() {
    window.localStorage.removeItem(ACTIVE_REFERRAL_CODE_KEY);
  }

  /**
   * Saves the locally active affiliate code local storage. This is used to track the affiliate code that is currently being used, not to be confused with the active referral code belonging to an external user.
   * @param {string} code - The referral code to be saved.
   * @returns {void} This function does not return a value.
   */
  saveLocalActiveAffiliateCode(code) {
    window.localStorage[LOCAL_ACTIVE_AFFILIATE_CODE_KEY] = code;
  }

  /**
   * Retrieves the local active affiliate code from local storage.
   * @returns {string | null} The active referral code if it exists in local storage, or null if it doesn't.
   */
  getLocalActiveAffiliateCode() {
    return window.localStorage[LOCAL_ACTIVE_AFFILIATE_CODE_KEY] || null;
  }
  saveLastDeletedProductCategory(category) {
    window.localStorage[LAST_DELETED_PRODUCT_CATEGORY] = category;
  }
  getLastDeletedProductCategory() {
    return window.localStorage[LAST_DELETED_PRODUCT_CATEGORY] || 'home';
  }
  deleteLastDeletedProductCategory() {
    window.localStorage.removeItem(LAST_DELETED_PRODUCT_CATEGORY);
  }
}) || _class);