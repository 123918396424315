var _dec, _class;
import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastType } from 'resources/helpers/enums';
export let ToastService = (_dec = inject(EventAggregator), _dec(_class = class ToastService {
  constructor(eventAggregator) {
    this.defaultTitles = {
      [ToastType.SUCCESS]: 'Success'
    };
    this.eventAggregator = eventAggregator;
  }
  async showToast(toastType, message, title, imgIcon, screenTime, url, urlName, clickHereActive, actionLabel) {
    if (screenTime === void 0) {
      screenTime = 2500;
    }
    if (clickHereActive === void 0) {
      clickHereActive = false;
    }
    title ??= this.defaultTitles[toastType] ?? toastType.toPascal();
    this.eventAggregator.publish('toast', {
      title,
      message,
      toastType,
      imgIcon,
      forceOut: false,
      screenTime: screenTime,
      url: url,
      urlName: urlName,
      clickHereActive: clickHereActive,
      actionLabel: actionLabel
    });
  }
}) || _class);