var _dec, _class;
import { inject } from 'aurelia-framework';
import { reCaptchaKey } from 'environment';
import { Helper } from 'resources/extensions/helper';
export let ScriptService = (_dec = inject(Helper), _dec(_class = class ScriptService {
  constructor(helper) {
    this.helper = helper;
  }
  injectIntercomScript() {
    this.helper.injectScript('intercom-script', '/intercom-facade/intercom-facade.min.js', true);
  }
  injectGoogleScript() {
    this.helper.injectScript('google-script', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBwRB5w56D8uTYXbNythSvbzJykWFqhgQg&loading=async&callback=Function.prototype&libraries=places');
  }
  injectGoogleReCaptchaScript() {
    this.helper.injectScript('recaptcha-script', `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey()}`, true);
  }
  injectGooglePayScript() {
    this.helper.injectScript('google-pay-script', 'https://pay.google.com/gp/p/js/pay.js', true);
  }
  injectApplePayScript() {
    this.helper.injectScript('apple-pay-script', 'https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js', true, true);
  }
}) || _class);