var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
export let HealthService = (_dec = inject(ApiService), _dec(_class = class HealthService {
  constructor(api) {
    this.path = 'Health';
    this.api = api;
  }
  async checkApiHealth() {
    return await this.api.doGet(this.path);
  }
}) || _class);