var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/extensions/helper';
export let PageContentAreaService = (_dec = inject(ApiService, EventAggregator, Helper), _dec(_class = class PageContentAreaService {
  constructor(api, eventAggregator, helper) {
    this.path = 'PageContentArea';
    this.retrievedContent = [];
    this.previewsCode = '';
    this.getImageAltByKey = async key => {
      const content = await this.getByKey(key);
      return content?.imageAlt;
    };
    this.api = api;
    this.eventAggregator = eventAggregator;
    this.helper = helper;
  }
  async getById(id) {
    return await this.api.doGet(this.path + '/' + id);
  }
  async getByKey(key) {
    const delimiter = '_';
    const start = 2;
    if (key) {
      const tokens = key.split(delimiter);
      [tokens.slice(0, start), tokens.slice(start)].map(item => {
        return item.join(delimiter);
      });
    }
    const foundContent = this.retrievedContent.find(x => x.key === key);
    if (foundContent) {
      return foundContent;
    } else {
      return null;
    }
  }
  async getByPageId(pageId) {
    const params = {
      pageId: pageId,
      websiteShortCode: websiteShortCode()
    };
    const content = await this.api.doGet(this.path + '/ByPage', params);
    this.retrievedContent.push(...content);
    this.publishContent();
    return this.retrievedContent;
  }
  async getByPageIdAndGame(pageId, gamePage) {
    if (!this.retrievedContent.some(x => x.key?.includes(gamePage)) && pageId && gamePage) {
      const params = {
        pageId: pageId,
        websiteShortCode: websiteShortCode(),
        gamePage: gamePage
      };
      const content = await this.api.doGet(this.path + '/ByPageAndGame', params);
      this.retrievedContent.push(...content);
    }
    this.publishContent();
    return this.retrievedContent;
  }
  publishContent() {
    this.helper.debounce(this, 'sendingContent', 'contentTimeout', 350, () => this.eventAggregator.publish('retrieved-content-changed', this.retrievedContent));
  }
}) || _class);