var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
export let BlacklistService = (_dec = inject(ApiService, Helper), _dec(_class = class BlacklistService {
  constructor(api, helper) {
    this.path = 'Blacklist';
    this.userBlacklist = void 0;
    this.api = api;
    this.helper = helper;
  }
  async getBlacklistByUserEmail(userEmail, updateList) {
    if (updateList === void 0) {
      updateList = false;
    }
    if (this.userBlacklist?.length > 0 && !updateList) {
      return this.userBlacklist;
    }
    this.userBlacklist = await this.helper.fetchData(this.api, `${this.path}/${userEmail}/GetByUserEmailProfile`, `BlacklistService/${userEmail}`, updateList);
    return this.userBlacklist;
  }
}) || _class);