var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
import { staticProductCategories } from 'resources/constants';
export let ProductCategoryService = (_dec = inject(ApiService, Helper), _dec(_class = class ProductCategoryService {
  /**
   * @param {ApiService} api
   * @param {Helper} helper
   */
  constructor(api, helper) {
    this.path = 'ProductCategory';
    this.navItems = void 0;
    this.productCategories = void 0;
    this.staticProductCategoriesForSell = staticProductCategories().map(x => x.toLowerCase());
    this.api = api;
    this.helper = helper;
  }
  async getAll() {
    if (this.productCategories) return this.productCategories;
    this.productCategories = await this.api.doGet(this.path);
    this.productCategories = this.productCategories.filter(x => x.name !== 'Sell Gift Cards');
    return this.productCategories;
  }
  async getAllForNav(websiteShortCode) {
    return this.helper.handlePendingRequest(this, async () => {
      if (!this.navItems) {
        this.navItems = await this.api.doGet(this.path + '/ForNav?websiteShortCode=' + websiteShortCode);
      }
      this.buildExtraSell();
      return this.navItems?.filter(c => c.name !== 'sellextra' && c.name !== 'WOWTLKPW Power Leveling' && c.name !== 'Sell Gift Cards');
    });
  }
  async getNavCategory(category, websiteShortCode) {
    if (category) {
      if (!this.navItems) {
        this.navItems = await this.api.doGet(this.path + `/ForNav?websiteShortCode=${websiteShortCode}`);
      }
      this.buildExtraSell();
      return this.navItems.find(x => x.name?.toLowerCase() === category?.toLowerCase());
    } else {
      throw Error('Category given was null');
    }
  }
  async getIdByName(name) {
    return await this.api.doGet(this.path + '/GetIdByName/' + name);
  }
  buildExtraSell() {
    if (this.navItems?.some(x => x.name === 'sellextra')) return;
    const auxNavItems = JSON.parse(JSON.stringify(this.navItems));
    const sellGames = this.staticProductCategoriesForSell.map(x => {
      let name = ['items', 'accounts'].includes(x) ? x.slice(0, -1) : x;
      name = this.helper.toCapitalize(name, true);
      return auxNavItems?.find(i => i.name === `Sell ${name}`)?.gameForNav?.map(e => {
        e.slug = e.slug.map(s => `${this.helper.toRoute(x)}/${s}`);
        e.productCategoryName = x.toUpperCase();
        return e;
      });
    }).filter(x => x);
    const sellList = {
      id: 250,
      name: 'sellextra',
      gameForNav: sellGames.flat()
    };
    this.navItems?.push(sellList);
  }
}) || _class);