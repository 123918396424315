var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
export let SiteSettingService = (_dec = inject(ApiService, Helper), _dec(_class = class SiteSettingService {
  constructor(api, helper) {
    this.path = 'SiteSetting';
    this.siteSettings = void 0;
    this.fingerprint = void 0;
    this.api = api;
    this.helper = helper;
  }
  async getSiteSettings() {
    if (!this.siteSettings) await this.getAll();
    return this.siteSettings;
  }
  async getBeforeExecution(callback) {
    await this.getSiteSettings();
    return await callback();
  }
  async getKey(key) {
    return await this.getBeforeExecution(() => {
      return this.siteSettings?.find(x => x.key === key);
    });
  }
  async getAll() {
    this.siteSettings = await this.helper.fetchData(this.api, this.path, 'getSiteSettings');
  }
  async getById(id) {
    return await this.api.doGet(this.path + '/' + id);
  }
  async getByKey(key) {
    return await this.api.doGet(this.path + '/ByKey/' + key);
  }
  async getDeviceInfoForUser() {
    if (!this.siteSettings) this.siteSettings = await this.getSiteSettings();
    const deviceCheckerEnabled = this.siteSettings?.find(x => x.key === 'DeviceCheckEnabled');
    const existingFingerprint = this.helper.getWindowLocalStorageValue('fingerprint');
    if (deviceCheckerEnabled && parseInt(deviceCheckerEnabled.value) <= 0) return;
    if (existingFingerprint) return existingFingerprint;
    this.fingerprint = await this.helper.fetchFingerprintForUser();
    if (!this.fingerprint) return;
    this.helper.saveWindowLocalStorageValue('fingerprint', this.fingerprint);
    return this.fingerprint;
  }
}) || _class);