var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
export let ReferralService = (_dec = inject(ApiService), _dec(_class = class ReferralService {
  constructor(api) {
    this.path = 'ReferralLink';
    this.api = api;
  }
  async submitReferralLink(url, linkTypeId) {
    return await this.api.doPost(`${this.path}/submit?referralLinkUrl=${url}&linkTypeId=${linkTypeId}`);
  }
}) || _class);