var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
export let UserDocumentService = (_dec = inject(ApiService, Helper), _dec(_class = class UserDocumentService {
  constructor(api, helper) {
    this.path = 'UserDocument';
    this.userDocuments = void 0;
    this.api = api;
    this.helper = helper;
  }
  getStatus(data, type) {
    if (data.find(x => x.userDocumentVerificationCategoryId === type && !x.userDeleted)?.verified === null) {
      return true;
    } else {
      return false;
    }
  }
  async getUserDocumentsById(id, type) {
    if (!this.userDocuments) {
      this.userDocuments = await this.helper.fetchData(this.api, this.path + '/GetUserDocumentsById/' + id, 'UserDocumentService');
    }
    return this.getStatus(this.userDocuments, type);
  }
  async deleteByUserIdAndCategoryId(userId, categoryId) {
    await this.api.doDelete(this.path + '/DeleteForUserByUserIdAndCategoryId/' + userId + '/' + categoryId);
  }
}) || _class);