var _dec, _class;
import { debug, signalrEndpoint, websiteShortCode } from 'environment';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { inject } from 'aurelia-framework';
import { SessionService } from './session-service';
export let SignalRService = (_dec = inject(SessionService), _dec(_class = class SignalRService {
  /**
   * @param {SessionService} sessionService
   */
  constructor(sessionService) {
    this.signalRConnection = void 0;
    this.sessionService = sessionService;
  }
  checkIfUserConnected() {
    return this.signalRConnection;
  }

  /**
   * @returns {Promise<HubConnection>}
   */
  async getSignalRConnection() {
    if (!this.signalRConnection || this.signalRConnection?.state !== 'Connected') {
      if (!this.signalRConnection && !this.triggerOnceSetup) {
        this.triggerOnceSetup = true;
        const connection = await this.setupSignalRConnection();
        await this.start(connection);
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      await this.getSignalRConnection();
    }
    return this.signalRConnection;
  }
  async setupSignalRConnection() {
    if (this.signalRConnection) return;
    return this.signalRConnection = new HubConnectionBuilder().withUrl(`${signalrEndpoint()}signalRHub`, {
      accessTokenFactory: async () => await this.sessionService.getToken(),
      withCredentials: false
    }).withAutomaticReconnect({
      // Attempt reconnects for less than 1 minute in random delay of 0 - 10 secs,
      // Otherwise stop reconnect after multiple failed reconnects after 1 minute exhaust.
      nextRetryDelayInMilliseconds: retryContext => {
        if (retryContext.elapsedMilliseconds < 60000) {
          return Math.random() * 10000;
        } else {
          return null;
        }
      }
    }).configureLogging(debug() ? LogLevel.Information : LogLevel.None).build();
  }
  async start(connection) {
    try {
      await connection.start();
    } catch (err) {
      setTimeout(async () => await this.start(connection), 5000);
    }
  }
  async untrackUserConnection() {
    try {
      await this.signalRConnection.invoke('UnTrackUserConnection', websiteShortCode());
    } catch (e) {
      console.log(e);
    }
  }
  async trackUserConnection() {
    try {
      await this.signalRConnection.invoke('TrackUserConnection', websiteShortCode());
    } catch (e) {
      console.log(e);
    }
  }
}) || _class);