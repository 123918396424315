var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { websiteShortCode } from 'environment';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';
import { SiteSettingService } from './site-setting-service';
import moment from 'moment';
import { NotificationType, ReasonType } from 'services/models/interceptor/notificationType';
import { ErrorReason } from 'services/models/interceptor/errorReason';
import { ToastService } from './toast-service';
import { ToastType } from 'resources/helpers/enums';
import { IPasswordResetRequest } from '@chicksgroup/types';
export let CustomerService = (_dec = inject(ApiService, EventAggregator, CamelCaseValueConverter, Helper, SiteSettingService, ToastService), _dec(_class = class CustomerService {
  constructor(api, eventAggregator, camelCaseValueConverter, helper, siteSettingService, toastService) {
    this.path = 'User';
    this.idPath = this.path + '/';
    this.get = async path => await this.api.doGet(`${this.path}/${path}`);
    this.hasWithdrawPassword = async () => await this.get('HasWithdrawPassword');
    this.api = api;
    this.eventAggregator = eventAggregator;
    this.camelCaseValueConverter = camelCaseValueConverter;
    this.helper = helper;
    this.siteSettingService = siteSettingService;
    this.toastService = toastService;
  }
  async register(data) {
    data.websiteShortCode = websiteShortCode();
    data.fingerprint = await this.siteSettingService.getDeviceInfoForUser();
    const registerResponse = await this.api.doPost(this.path, data);
    if (registerResponse) {
      if (registerResponse.alreadyRegistered) {
        this.toastService.showToast(ToastType.INFO, `Account was created on ${moment.utc(registerResponse.createdDate).local().format('MM/DD/YYYY')} on ${registerResponse.website?.name ?? 'Chicks Gold'}. Please sign in instead with the same credentials.`, 'Welcome back');
        return true;
      }
    }
    this.helper.handleGtagEvent('sign_up', null, null, null, null, data?.signInOption);
    this.helper.handleFacebookPixelEvent('CompleteRegistration');
    this.helper.handleRedditEvent('SignUp');
    return registerResponse;
  }
  async send2FAEmail(userEmail, userPassword) {
    return await this.api.doPost(`${this.path}/Send2FAEmail`, {
      userEmail,
      userPassword,
      websiteShortCode: websiteShortCode()
    });
  }
  async reset2FAByEmail(userEmail, token) {
    return await this.api.doGet(this.helper.toParams(this.path + '/Reset2FAByEmail', {
      userEmail,
      token,
      websiteShortCode: websiteShortCode()
    }));
  }
  async verify2FAEmail(userEmail, token) {
    return await this.api.doGet(this.helper.toParams(this.path + '/Verify2FAEmail', {
      userEmail,
      token,
      websiteShortCode: websiteShortCode()
    }));
  }
  async sendWithdrawEmail() {
    return await this.api.doGet(this.helper.toParams(this.path + '/SendWithdrawEmail', {
      websiteShortCode: websiteShortCode()
    }));
  }
  async resetWithdrawPasswordByEmail(userEmail, token) {
    return await this.api.doGet(this.helper.toParams(this.path + '/ResetWithdrawPasswordByEmail', {
      userEmail,
      token,
      websiteShortCode: websiteShortCode()
    }));
  }
  async verifyWithdrawEmail(userEmail, token) {
    return await this.api.doGet(this.helper.toParams(this.path + '/VerifyWithdrawEmail', {
      userEmail,
      token,
      websiteShortCode: websiteShortCode()
    }));
  }
  async updateInformation(data) {
    data.websiteShortCode = websiteShortCode();
    return await this.api.doPut(this.path + '/updateInformation', data);
  }
  async deleteProfile(password) {
    return await this.api.doDelete(this.path + '/deleteProfile', password);
  }
  async requestEmailConfirmation() {
    return await this.api.doGet(this.path + '/RequestEmailConfirmation?websiteShortCode=' + websiteShortCode());
  }
  async sendContactEmail(from, message, topic, name, attachments) {
    if (attachments === void 0) {
      attachments = null;
    }
    const data = {
      from: from,
      message: message,
      topic: topic,
      name: name,
      websiteShortCode: websiteShortCode(),
      attachments: attachments ? JSON.stringify({
        files: attachments
      }) : null
    };
    return await this.api.doPost('Contact', data);
  }
  async checkIfUserDeleted() {
    return await this.api.doGet(this.path + '/checkIfUserDeleted');
  }
  async setPaymentMethod(paymentMethodId) {
    const data = {
      paymentMethodId: paymentMethodId
    };
    return await this.api.doPut(this.path + '/updateInformation', data);
  }
  async requestTwoFactorAuthentication() {
    return await this.api.doGet(this.path + '/requestTwoFactorAuthentication');
  }
  async confirmTwoFactorActivation(token) {
    return await this.api.doPost(this.path + '/confirmTwoFactorActivation', {
      token
    });
  }
  async checkIfTwoFactorEnabled() {
    return await this.api.doGet(this.path + '/checkIfTwoFactorEnabled');
  }
  async removeTwoFactorAuthentication(token) {
    return await this.api.doPost(this.path + '/removeTwoFactorAuthentication', {
      token
    });
  }

  /**
   * @param {IPasswordResetRequest} data
   * @returns {Promise<boolean>}
   */
  async requestPasswordReset(data) {
    data.websiteShortCode = websiteShortCode();
    return await this.api.doPost('requestResetPassword', data);
  }
  async updatePassword(currentPassword, newPassword, isWithdraw) {
    if (isWithdraw === void 0) {
      isWithdraw = false;
    }
    const response = await this.api.doPost(this.path + '/updatePassword', {
      currentPassword,
      newPassword,
      isWithdraw
    });
    if (response === null) throw new Error('Failed to update password');
    return response;
  }
  async resetPasswordWithToken(email, newPassword, token) {
    const data = {
      newPassword,
      email,
      accessToken: token
    };
    return await this.api.doPost('ResetPassword', data);
  }
  async verifyResetPasswordToken(token, email) {
    const data = {
      token,
      email
    };
    return await this.api.doGet('VerifyPasswordResetToken', data);
  }
  async registerPhone(countryCode, phone, countryFlag) {
    const data = {
      phoneNumber: phone,
      countryCode: countryCode,
      phoneCountryFlag: countryFlag
    };
    return await this.api.doPost(this.path + '/RegisterPhoneNumber', data);
  }
  async requestCall() {
    return await this.api.doGet(this.path + '/RequestCallVerification');
  }
  async verifyPhone(token, usingTwilio, verificationId, idToken) {
    const data = {
      token: token,
      usingTwilio: usingTwilio,
      verificationId: verificationId,
      idToken: idToken
    };
    return await this.api.doPost(this.path + '/VerifyPhoneNumber', data);
  }
  async confirmEmail(email, token) {
    const data = {
      email: email,
      token: token
    };
    return await this.api.doPost(this.path + '/ConfirmEmail', data);
  }
  async verifyUserReCaptcha(token) {
    const data = {
      token: token
    };
    return await this.api.doPost(this.path + '/VerifyUserReCaptcha', data);
  }
  async startVerification(userId, verification) {
    return await this.api.doPost(`${this.path}/${userId}/StartVeriffSession`, {
      verification
    });
  }
  async getDocumentDecision(sessionId) {
    return await this.api.doGet(`${this.path}/${sessionId}/GetDocumentDecision`);
  }
  async verificationCategoryCriteriaMet(verificationCategoryName) {
    return await this.api.doGet(`${this.path}/${verificationCategoryName}/GetUserVerificationCategoryCriteriaMet`);
  }
  async getCartData(cartItem, userId, loggingIn, updateCart, orderId) {
    if (orderId === void 0) {
      orderId = 0;
    }
    const cartItemObject = Object.assign({}, Array.isArray(cartItem) ? cartItem[0] : cartItem);
    const newCartItem = Object.assign({}, cartItemObject, {
      userCartId: cartItemObject.userCartId ? cartItemObject.userCartId : 0,
      quantity: this.helper.convertNumberWithoutComma(cartItemObject.quantity, 'float'),
      tempQuantity: this.helper.convertNumberWithoutComma(cartItemObject.quantity, 'float'),
      id: cartItemObject.id ?? cartItemObject.productId
    });
    return {
      userId: userId,
      loggingIn: loggingIn ? loggingIn : false,
      updateCart: updateCart ? updateCart : false,
      product: newCartItem,
      orderId: cartItem.orderId || orderId
    };
  }
  async storeUserCartProducts(cartItem, userId, loggingIn, updateCart, orderId, updateOrder) {
    if (orderId === void 0) {
      orderId = 0;
    }
    if (updateOrder === void 0) {
      updateOrder = true;
    }
    const cartData = await this.getCartData(cartItem, userId, loggingIn, updateCart, orderId);
    return await this.api.doPost(`${this.path}/StoreUserCartProducts`, {
      ...cartData,
      updateCart,
      updateOrder
    });
  }
  async onCartUpdated() {
    return await this.api.doPost(`${this.path}/OnCartUpdated`);
  }
  async destroyUserCartProducts(id) {
    if (id === void 0) {
      id = 0;
    }
    const cart = await this.getUserCartProducts();
    const orderId = cart.some(x => x.orderId !== null && x?.orderId > 0) ? cart.find(x => x.orderId !== null && x?.orderId > 0).orderId : 0;
    return await this.api.doDelete(`${this.path}/${id}/DestroyUserCartProducts/${orderId}`);
  }
  async updateCartAfterPurchase(id) {
    if (id === void 0) {
      id = 0;
    }
    const cart = await this.getUserCartProducts();
    const order = cart.some(x => x.orderId !== null) ? cart.find(x => x.orderId !== null).orderId : 0;
    const cartId = cart.some(x => x.orderId !== null) ? cart.find(x => x.orderId !== null).userCartId : 0;
    return await this.api.doPut(`${this.path}/${cartId}/updateCartAfterPurchase/${order}`);
  }
  async getUserCartProducts(forceFetch) {
    if (forceFetch === void 0) {
      forceFetch = false;
    }
    return await this.helper.fetchData(this.api, `${this.path}/GetUserCartProducts`, 'getUserCartProducts', forceFetch);
  }
  async refreshCart(cart, currencyUsed, orderAdClicks) {
    const newCart = cart.map(x => Object.assign({}, x, {
      quantity: this.helper.convertNumberWithoutComma(x.quantity, 'float'),
      tempQuantity: this.helper.convertNumberWithoutComma(x.tempQuantity, 'float')
    }));
    const data = {
      currencyUsed,
      orderAdClicks,
      products: newCart,
      services: newCart.filter(item => item.isService)
    };
    return await this.api.doPost(`${this.path}/RefreshUserCartProducts`, data);
  }
  async getVeriffUserData(id) {
    return await this.api.doGet(`${this.path}/${id}/GetVeriffUserData`);
  }
  async requestIpAuthorization(data) {
    data.websiteShortCode = websiteShortCode();
    return await this.api.doPost('RequestIpAuthorization', data);
  }
  async authorizeIp(data) {
    data.websiteShortCode = websiteShortCode();
    return await this.api.doPost('AuthorizeIp', data);
  }
  async checkEmail(email) {
    return await this.api.doGet(`${this.path}/CheckEmail/${email?.trim()}`);
  }
  async verify2FA(token) {
    return await this.api.doPost(`${this.path}/VerifyTwoFactor`, {
      token
    });
  }
  async linkDiscordToProfile(tokenType, accessToken) {
    return await this.api.doPost(`${this.path}/LinkDiscordToProfile`, {
      tokenType,
      accessToken
    });
  }
  async updateDiscordTag() {
    return await this.api.doPost(`${this.path}/UpdateDiscordTag`);
  }
  async getDiscordProfile(tokenType, accessToken) {
    return await this.api.doGet(`${this.path}/GetDiscordProfile/${tokenType}/${accessToken}`);
  }
  async linkSteamToProfile(steamId) {
    return await this.api.doPost(`${this.path}/LinkSteamToProfile`, {
      steamId
    });
  }
  async updateSteamName() {
    return await this.api.doPost(`${this.path}/UpdateSteamName`);
  }
  async getPendingBalanceByUserId() {
    return await this.api.doGet(`${this.path}/GetPendingBalanceByUserId`);
  }
  async validateApplePaySession(applePayUrl) {
    return await this.api.doPost(`${this.path}/ApplePaySession`, applePayUrl);
  }
  async createWithdrawPassword(newWithdrawPassword, currentWithdrawPassword) {
    return await this.api.doPost(`${this.path}/CreateWithdrawPassword`, {
      newWithdrawPassword,
      currentWithdrawPassword
    });
  }
  async verifyWithdrawPassword(withdrawPassword) {
    const response = await this.api.doPost(`${this.path}/VerifyWithdrawPassword`, withdrawPassword);
    const handler = [new ReasonType(ErrorReason.TooManyRequests, NotificationType.Info, null, {
      avoidNotification: true
    })];
    return this.helper.requestErrorHandler(response, handler);
  }
  async getTotalSpentByUser(id) {
    return await this.api.doGet(`${this.path}/TotalSpentByUser/${id}`);
  }
  async hasFailedPasswordAttempts(id) {
    return await this.api.doGet(`${this.path}/HasFailedPasswordAttempts`);
  }
  getVerificationLevel(user) {
    const validations = [user.phoneVerified, user.emailConfirmed || user.idVerified, user.addressVerified];
    let level = 0;
    for (const validation in validations) {
      if (validation) level++;else break;
    }
    return level;
  }
}) || _class);