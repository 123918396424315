var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode, getAWSBucketEndpoint, baseUrl } from 'environment';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import numeral from 'numeral';
import { Helper } from 'resources/extensions/helper';
import { OrderTransactionType } from '../resources/helpers/enums'; // eslint-disable-line no-unused-vars

const ORDER_PATH = 'order_path';
export let OrderService = (_dec = inject(ApiService, CamelCaseValueConverter, Helper), _dec(_class = class OrderService {
  /**
   * @param {ApiService} api
   * @param {CamelCaseValueConverter} camelCaseValueConverter
   * @param {Helper} helper
   */
  constructor(api, camelCaseValueConverter, helper) {
    this.path = 'Order';
    this.orders = void 0;
    this.validateImagePathConditions = (product, productCategory, game) => {
      switch (productCategory.name) {
        case 'Currency':
          return product?.schemaImagePath ?? product?.imagePath;
        case 'Services':
          return game?.schemaImagePath ?? game?.gameNavigationIcon;
        default:
          return product?.imagePath;
      }
    };
    this.api = api;
    this.camelCaseValueConverter = camelCaseValueConverter;
    this.amazonProductBaseUrl = getAWSBucketEndpoint('products');
    this.amazonGameBaseUrl = getAWSBucketEndpoint('games');
    this.helper = helper;
  }
  async getAll() {
    if (this.orders) return this.orders;
    this.orders = await this.api.doGet(this.path + '?websiteShortCode=' + websiteShortCode());
    return this.orders;
  }

  /**
   * @param {number} recordQuantity
   * @param {number} page
   * @param {string | null} status
   * @param {string | null} search
   * @param {boolean} isBalance
   * @param {boolean | null} isSell
   * @param {OrderTransactionType | null} transactionType
   * @returns {any}
   */
  getAllWithPagination(recordQuantity, page, status, search, isBalance, isSell, transactionType) {
    if (status === void 0) {
      status = null;
    }
    if (search === void 0) {
      search = null;
    }
    if (isBalance === void 0) {
      isBalance = false;
    }
    if (isSell === void 0) {
      isSell = false;
    }
    if (transactionType === void 0) {
      transactionType = nulll;
    }
    status = status === 'All' || status === '' ? null : status;
    return this.api.doGet(this.helper.toParams(this.path + '/ListOrdersPagination', {
      websiteShortCode: websiteShortCode(),
      recordQuantity,
      page,
      status,
      search,
      isBalance,
      isSell
    }));
  }

  /**
   * Gets the user's orders listed by pagination
   * @param {number} recordQuantity Amount of orders to return
   * @param {number} page Current page the user is in
   * @param {string | null} status The status of the order to filter them by it
   * @param {string | null} search A query to use to filter out the orders
   * @param {boolean} isBalance Whether to return the orders from Balance or not
   * @param {boolean | null} isSell Whether to return the sold orders or not
   * @param {import('../resources/helpers/enums').OrderTransactionType | null} transactionType Whether it's a Purchased, Sold or a Swap order
   * @param {boolean} isCashback Whether to return the orders that contain Subscription Cashback or not
   * @returns {Promise<PurchasedOrder[]>} A promise with the list of orders filtered by current page
   */
  getCGListWithPagination(recordQuantity, page, status, search, isBalance, isSell, transactionType, isCashback, isReferral) {
    if (status === void 0) {
      status = null;
    }
    if (search === void 0) {
      search = null;
    }
    if (isBalance === void 0) {
      isBalance = false;
    }
    if (isSell === void 0) {
      isSell = null;
    }
    if (transactionType === void 0) {
      transactionType = null;
    }
    if (isCashback === void 0) {
      isCashback = false;
    }
    if (isReferral === void 0) {
      isReferral = false;
    }
    return this.api.doGet(this.helper.toParams(this.path + '/CGListOrdersPagination', {
      websiteShortCode: websiteShortCode(),
      recordQuantity,
      page,
      status,
      search,
      isBalance,
      isSell,
      transactionType,
      isCashback,
      isReferral
    }));
  }

  /**
   * Gets the total count of orders from the user
   * @param {string | null} status The status of the order to filter them by it
   * @param {string | null} search A query to use to filter out the orders
   * @param {boolean} isBalance Whether to return the orders from Balance or not
   * @param {boolean | null} isSell Whether to return the sold orders or not
   * @param {import('../resources/helpers/enums').OrderTransactionType | null} transactionType Whether it's a Purchased, Sold or a Swap order
   * @param {boolean} isCashback Whether to return the orders that contain Subscription Cashback or not
   * @returns {Promise<number>} A promise with the total count of orders
   */
  getOrdersCountByWebsiteByUserId(status, search, isBalance, isSell, transactionType, isCashback, isReferral) {
    if (status === void 0) {
      status = null;
    }
    if (search === void 0) {
      search = null;
    }
    if (isBalance === void 0) {
      isBalance = false;
    }
    if (isSell === void 0) {
      isSell = null;
    }
    if (transactionType === void 0) {
      transactionType = null;
    }
    if (isCashback === void 0) {
      isCashback = false;
    }
    if (isReferral === void 0) {
      isReferral = false;
    }
    return this.api.doGet(this.helper.toParams(this.path + '/GetOrdersCountByWebsiteByUserId', {
      websiteShortCode: websiteShortCode(),
      status,
      search,
      isBalance,
      isSell,
      transactionType,
      isCashback,
      isReferral
    }));
  }

  /**
   * Retrieves an order by its ID.
   *
   * @param {string} id - The unique identifier of the order.
   * @returns {Promise<PurchasedOrder>} A promise that resolves to the order data.
   */
  getById(id) {
    return this.api.doGet(`${this.path}/${id}`, {
      small: true,
      path: this.getOrderPath()
    });
  }

  /**
   * Gets the most recent order made by the user
   * @param {import('../resources/helpers/enums').OrderTransactionType | null} transactionType
   * @returns {Promise<RecentOrder>} A promise with the most recent order of the user
   */
  getMostRecentOrder(transactionType) {
    if (transactionType === void 0) {
      transactionType = null;
    }
    return this.api.doGet(this.helper.toParams(`${this.path}/GetMostRecentOrder`, {
      transactionType
    }));
  }
  async startOrder(data) {
    return this.api.doPost(this.path, data);
  }
  async resendReceipt(orderId) {
    return this.api.doPost(this.path + '/' + orderId + '/ResendReceipt');
  }
  async updateSkrillDirectStatus(id, status) {
    return this.api.doPost(`${this.path}/${id}/${status}/updateSkrillDirectStatus`);
  }
  async createTopUpBalanceOrder(order) {
    return await this.api.doPost(`${this.path}/CreateTopUpBalanceOrder`, order);
  }
  async createSubscriptionOrder(order) {
    return await this.api.doPost(`${this.path}/CreateSubscriptionOrder`, order);
  }
  async createDynamicServiceOrUniqueNamesOrder(order) {
    return await this.api.doPost(`${this.path}/CreateDynamicServiceOrUniqueNamesOrder`, order);
  }
  async checkIfUserHasFulfilledOrders(quantity, fromAds, orderWebsiteShortCode) {
    return await this.api.doGet(`${this.path}/${quantity}/${fromAds}/checkIfUserHasFulfilledOrders${orderWebsiteShortCode ? `?orderWebsiteShortCode=${orderWebsiteShortCode}` : ''}`);
  }
  async checkIfFirstOrderFromAds() {
    return await this.api.doGet(`${this.path}/CheckIfFirstOrderFromAds`);
  }
  async getTotalOrderCountByGameAndProductCategory(gameId, productCategoryId) {
    return await this.api.doGet(`${this.path}/${gameId}/${productCategoryId}/TotalOrderCountByGameAndProductCategory?websiteShortCode=CG`);
  }

  /**
   * @param {CustomOrderRequest} data
   * @returns {Promise<CustomOrder | null>}
   */
  async startCustomOrder(data) {
    return await this.api.doPost(`${this.path}/CreateCustomOrder`, data);
  }
  async updateOrderStatus(orderId, status) {
    let response = await this.api.doPost(`${this.path}/${orderId}/${status}/UpdateOrderStatus`);
    if (response && typeof response !== 'string') {
      response = this.camelCaseValueConverter.toView(response);
    }
    return response;
  }
  async handleProductRatingSchema(game, productCategory, product, currency, overridedPrice, isSpecificProduct) {
    if (isSpecificProduct === void 0) {
      isSpecificProduct = false;
    }
    const currencyCategoryName = () => {
      let name = product?.game?.name;
      switch (product?.game?.shortName) {
        case 'POE':
          name += ' Currency';
          break;
        case 'FFXIV':
          name += ' Gil';
          break;
        case 'NW':
          name += ' Coins';
          break;
        case 'EFT':
          name += ' Roubles';
          break;
        default:
          name += ' Gold';
          break;
      }
      return name;
    };
    const totalOrderCount = await this.getTotalOrderCountByGameAndProductCategory(game.id, productCategory.id);
    let productSchema = `{
                            "@type": "Product",
                            "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}",
                            "name": "${isSpecificProduct && productCategory.name !== 'Currency' ? product.name : isSpecificProduct && productCategory.name === 'Currency' ? currencyCategoryName() : `${game.name} ${productCategory.name}` ?? ''}",
                            "aggregateRating": {
                                "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}/AggregateRating"
                            }`;
    const imagePath = this.validateImagePathConditions(product, productCategory, game);
    if (imagePath) productSchema += `,\n"image": "${productCategory.name === 'Services' ? this.amazonGameBaseUrl : this.amazonProductBaseUrl}${imagePath}"`;else {
      let defaultImagePath = 'icons/';
      switch (product?.productCategory?.name ?? productCategory?.name) {
        case 'Currency':
          defaultImagePath += 'diamond.svg';
          break;
        case 'Items':
          defaultImagePath += 'sword-no-border.svg';
          break;
        case 'Accounts':
          defaultImagePath += 'icon_account.svg';
          break;
        case 'Services':
          defaultImagePath = 'home/circle-arrow.svg';
          break;
        case 'Swap':
          defaultImagePath = 'home/circle-swap.svg';
          break;
        case 'Skins':
          defaultImagePath += 'icon_skins.svg';
          break;
        default:
          defaultImagePath += 'chicks-crown.png';
          break;
      }
      productSchema += `,\n"image": "${baseUrl()}${defaultImagePath}"`;
    }
    if (product?.description) productSchema += `,\n"description": "${this.helper.sanitizeHtml(product.description, true).replaceAll('"', '')}"`;
    productSchema += `,
                                    "brand": {
                                        "@id": "${baseUrl()}#/schema/Brand/ChicksGold"
                                    }
                                    `;
    if (isSpecificProduct) {
      productSchema += `,
                                        "offers": {
                                            "@id": "${baseUrl()}#/schema/Offer/1",
                                            "seller": {
                                                "@id": "${baseUrl()}#/schema/Organization/ChicksGold"
                                            }
                                        }
                                        `;
    }
    productSchema += '},';
    if (isSpecificProduct) {
      productSchema += `{
                                "@type": "Offer",
                                "@id": "${baseUrl()}#/schema/Offer/1",
                                "url": "${window.location.href}",
                                "priceCurrency": "${currency}",
                                "availability": "http://schema.org/InStock",
                                "price": ${productCategory.name === 'Currency' ? numeral(overridedPrice).format('0.00[000]') : numeral(overridedPrice).format('0.00')},
                                "lowPrice": ${productCategory.name === 'Currency' ? numeral(overridedPrice).format('0.00[000]') : numeral(overridedPrice).format('0.00')},
                                "highPrice": ${productCategory.name === 'Currency' ? numeral(overridedPrice).format('0.00[000]') : numeral(overridedPrice).format('0.00')}
                            },`;
    }
    productSchema += `{
                            "@type": "AggregateRating",
                            "@id": "${baseUrl()}#/schema/Product/${product?.id ?? productCategory.id}/AggregateRating",
                            "ratingValue": "4.9",
                            "reviewCount": "${totalOrderCount > 0 ? totalOrderCount : 1}",
                            "bestRating": "5",
                            "worstRating": "1"
                        },
                        {
                            "@type": "Brand",
                            "@id": "${baseUrl()}#/schema/Brand/ChicksGold",
                            "name": "Chicks Gold Inc."
                        }`;
    this.helper.combineApplicationLdJsonSchemasIntoOne(productSchema);
  }
  /**
   *
   * @param {number} orderId
   * @returns {Object} The new status of the order
   */
  async cancelOrder(orderId) {
    return await this.api.doPatch(`${this.path}/${orderId}/CancelOrder`);
  }

  /**
   *
   * @param {OrderTransactionType} transactionType
   * @returns {number} Total Price of all orders of said transaction type
   */
  async getCustomerTotalPriceOrders(transactionType) {
    return await this.api.doGet(`${this.path}/GetCustomerTotalPriceOrders`, {
      transactionType
    });
  }

  /**
   * Saves the given order path to local storage.
   *
   * @param {string} path - The path to be saved.
   * @returns {void}
   */
  saveOrderPath(path) {
    localStorage.setItem(ORDER_PATH, path);
  }

  /**
   * Retrieves the order path from the local storage.
   *
   * @returns {string|null} The order path if it exists in local storage, otherwise null.
   */
  getOrderPath() {
    return localStorage.getItem(ORDER_PATH);
  }

  /**
   * Removes the order path from the local storage.
   *
   * @returns {void}
   */
  removeOrderPath() {
    localStorage.removeItem(ORDER_PATH);
  }
  async paymentSpamCheck() {
    return await this.api.doGet(`${this.path}/Process`);
  }
}) || _class);