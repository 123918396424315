var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
export let WebsiteService = (_dec = inject(ApiService, Helper), _dec(_class = class WebsiteService {
  constructor(api, helper) {
    var _this2 = this;
    this.path = 'Website';
    this.pages = void 0;
    this.getPage = async name => await this.getAllBeforeExecution(() => this.pages.find(x => x.name.toLowerCase() === name.toLowerCase()));
    this.getRoute = async name => (await this.getPage(name))?.routeName;
    this.getRouteOrDefault = async function (name, def) {
      if (def === void 0) {
        def = -1;
      }
      return (await _this2.getRoute(name)) ?? (def !== -1 ? def : _this2.helper.toRoute(name));
    };
    this.getManyBy = async function (_this, names, extra, func) {
      if (extra === void 0) {
        extra = 'Page';
      }
      if (func === void 0) {
        func = _this2.getPage;
      }
      names.forEach(async name => {
        const variableName = `${_this2.helper.camelize(name)}${extra}`;
        _this[variableName] = await func(name);
      });
    };
    this.getManyPages = async (_this, names) => await this.getManyBy(_this, names);
    this.getManyRoutes = async (_this, names) => await this.getManyBy(_this, names, 'PageRoute', this.getRouteOrDefault);
    this.getPagesAndRoutes = async (_this, names) => {
      await this.getManyPages(_this, names);
      await this.getManyRoutes(_this, names);
    };
    this.getPagesDictionary = async () => await this.getAllBeforeExecution(() => {
      return this.pages.reduce((a, b) => {
        a[b.name] = b;
        return a;
      }, {});
    });
    this.api = api;
    this.helper = helper;
  }
  async getPagesByWebsiteShortcode() {
    if (!this.pages) this.pages = await this.api.doGet(`${this.path}/Pages?websiteShortCode=CG`);
    return this.pages;
  }
  async getAllBeforeExecution(func) {
    await this.getPagesByWebsiteShortcode();
    return func();
  }
}) || _class);