var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { SessionService } from './session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';
export let IpAddressService = (_dec = inject(ApiService, SessionService, EventAggregator, CamelCaseValueConverter, Helper), _dec(_class = class IpAddressService {
  constructor(api, sessionService, eventAggregator, camelCaseValueConverter, helper) {
    this.path = 'IpAddress';
    this.responseData = void 0;
    this.isRequestPending = false;
    this.requestQueue = [];
    this.api = api;
    this.sessionService = sessionService;
    this.eventAggregator = eventAggregator;
    this.camelCaseValueConverter = camelCaseValueConverter;
    this.helper = helper;
  }
  async postIp() {
    const response = await this.api.doPost(this.path, {});
    this.eventAggregator.publish('vpn-detection', {
      vpn: response.vpn,
      tor: response.tor,
      ip: response.ip
    });
    await this.saveUserProxy(response);
    await this.saveGeoLocation(response);
    return response;
  }
  async getIpData() {
    const response = await this.helper.fetchData(this.api, `${this.path}/GetIpData`, 'IpAddressService');
    if (response) await this.saveGeoLocation(response);
    return response;
  }
  async saveGeoLocation(geolocation) {
    this.sessionService.geolocation = geolocation;
  }
  async saveUserProxy(userProxy) {
    this.sessionService.userProxy = userProxy;
  }
}) || _class);