var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { DateOnlyValueConverter } from 'resources/value-converters/date-only';
import { ProductService } from './product-service';
import { Helper } from 'resources/extensions/helper';
import moment from 'moment';
export let SubscriptionService = (_dec = inject(ApiService, ProductService, DateOnlyValueConverter, Helper), _dec(_class = class SubscriptionService {
  constructor(api, productService, dateOnlyValueConverter, helper) {
    var _this = this;
    this.path = 'Subscription';
    this.subscriptions = void 0;
    this.getRoute = route => `${this.path}${route ? '/' : ''}${route}`;
    this.get = async route => await this.api.doGet(this.getRoute(route));
    this.put = async (route, request) => await this.api.doPut(this.getRoute(route), request);
    this.getParams = async (route, params) => await this.get(this.helper.toParams(route, params));
    this.getByUserId = async userId => await this.getParams('GetByUserId', {
      userId
    });
    this.getByName = async name => await this.getParams('GetByName', {
      name
    });
    this.getChicksVIP = async () => await this.getByName('Chicks VIP');
    this.getById = async id => await this.get(id);
    this.getProductsBySubscriptionName = async function (name) {
      if (name === void 0) {
        name = 'Chicks VIP';
      }
      return await _this.productService.getProductsWithFilter(`${name}`);
    };
    this.getActiveSubscription = (user, checkWithCancel) => user?.subscriptions?.find(x => {
      const isAfterRenewalDate = this.dateOnlyValueConverter.toView(x.renewalDate) > this.dateOnlyValueConverter.toView(new Date());
      const isSubscribed = x.isSubscribed;
      return checkWithCancel ? isAfterRenewalDate && isSubscribed : isAfterRenewalDate || isSubscribed;
    });
    this.hasChicksVipFreeTrial = async user => {
      if (!user) {
        return true;
      }
      return await this.get('CheckIfUserHasSubscriptionFreeTrial');
    };
    this.hasSubscription = (user, checkWithCancel) => Boolean(this.getActiveSubscription(user, checkWithCancel));
    this.getAvailableChicksVipProducts = async user => {
      if (this.hasSubscription(user)) {
        return;
      }
      let chicksVipProducts = await this.getProductsBySubscriptionName();
      chicksVipProducts = chicksVipProducts.filter(x => !x.name.includes('Renewal'));
      return chicksVipProducts.map(x => {
        x.productId = x.id;
        x.hasFreeTrial = user ? user.hasFreeTrial : true;
        if (x.hasFreeTrial) {
          x.priceAfterTrial = x.price;
          x.price = 0;
        }
        if (x.name.includes('Monthly') || x.hasFreeTrial) {
          x.validUntil = moment().add(30, 'days');
        } else {
          x.validUntil = moment().add(1, 'year');
        }
        x.quantity = x.tempQuantity = 1;
        return x;
      });
    };
    this.unsubscribeUser = async data => await this.api.doPost(`${this.path}/Unsubscribe`, {
      subscriptionId: data.subscriptionId,
      userId: data.userId,
      reasons: data.reasons,
      unsubscribeOtherReason: data.unsubscribeOtherReason
    });
    this.updateSubscriber = async function (data, renewal) {
      if (renewal === void 0) {
        renewal = false;
      }
      const request = {
        subscriptionId: data.subscriptionId,
        userId: data.userId,
        paymentPlan: data.paymentPlan,
        mainPaymentMethodId: data.mainPaymentMethodId,
        backupPaymentMethodId: data.backupPaymentMethodId
      };
      if (renewal) return _this.api.doPost(`${_this.path}/Subscribe`, request);
      return await _this.api.doPut(`${_this.path}/UpdateSubscriber`, request);
    };
    this.getAdCounter = async (subscriptionId, userId) => await this.getParams('GetSubscriptionAdCounter', {
      subscriptionId,
      userId
    });
    this.updateAdCounter = async subscriptionId => await this.put('UpdateSubscriptionAdCounter', subscriptionId);
    this.api = api;
    this.productService = productService;
    this.dateOnlyValueConverter = dateOnlyValueConverter;
    this.helper = helper;
  }
  async getAll() {
    if (!this.subscriptions) {
      this.subscriptions = await this.get();
    }
    return this.subscriptions;
  }
  calculateVIPCashback(product, cashback, price, category) {
    if (category === void 0) {
      category = null;
    }
    const productField = category ? product[category] : product;
    if (productField?.productCategory || productField?.productCategoryName) {
      const percent = ['Accounts', 'Services'].includes(productField?.productCategory?.name || productField?.productCategoryName) ? 0.1 : 0.03;
      const productPrice = Math.abs(product[price]);
      cashback += (productPrice - (product?.deliveryFee ?? 0)) * percent;
    }
    return cashback;
  }
  async totalCashbackEarned() {
    return await this.get('TotalCashbackEarned');
  }
}) || _class);