var _dec, _class;
import { inject, buildQueryString } from 'aurelia-framework';
import { HttpClient, json } from 'aurelia-fetch-client';
export let ApiService = (_dec = inject(HttpClient), _dec(_class = class ApiService {
  constructor(http) {
    this.http = http;
  }
  async _request(path, options) {
    const result = await this.http.fetch(path, options);
    if (result) {
      const status = result.status;
      if (status === 204) {
        return null;
      }
      if (status >= 400) {
        return null;
      }
      let response;
      if (['Health'].some(x => result.url?.includes(x))) {
        response = result.ok;
      } else if (result) {
        response = await result.json();
      }
      if (status >= 200 && status < 400) {
        return response;
      }
    }
    return null;
  }
  doGet(path, params) {
    const options = {
      method: 'GET'
    };
    if (params) {
      path += `?${buildQueryString(params)}`;
    }
    return this._request(path, options);
  }
  doPatch(path, body) {
    const options = {
      method: 'PATCH',
      body: json(body)
    };
    return this._request(path, options);
  }
  doPost(path, body, isFile, withCredentials) {
    if (isFile === void 0) {
      isFile = false;
    }
    if (withCredentials === void 0) {
      withCredentials = false;
    }
    return this._push(path, body, false, isFile, withCredentials);
  }
  doPut(path, body) {
    return this._push(path, body, true);
  }
  _fileUpload(path, formData) {
    const options = {
      method: 'POST',
      body: formData
    };
    return this._request(path, options);
  }
  async _fileDownload(path) {
    const options = {
      method: 'GET'
    };
    const result = await this.http.fetch(path, options);
    if (result) {
      const status = result.status;
      if (status === 204) {
        return null;
      }
      if (status > 400) {
        return null;
      }
      let response;
      if (result) {
        response = await result.blob();
      }
      if (status >= 200 && status < 400) {
        return response;
      }
    }
    return null;
  }
  doDelete(path, body) {
    const options = {
      method: 'DELETE'
    };
    if (body) options.body = json(body);
    return this._request(path, options);
  }
  _push(path, body, asPut, isFile, withCredentials) {
    if (asPut === void 0) {
      asPut = false;
    }
    if (isFile === void 0) {
      isFile = false;
    }
    if (withCredentials === void 0) {
      withCredentials = false;
    }
    const options = {
      method: asPut ? 'PUT' : 'POST',
      body: isFile ? body : json(body)
    };
    if (withCredentials) options.credentials = 'include';
    return this._request(path, options);
  }
}) || _class);