var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
export let PriceModificationService = (_dec = inject(ApiService), _dec(_class = class PriceModificationService {
  constructor(api) {
    this.path = 'PriceModification';
    this.priceModifications = void 0;
    this.api = api;
  }
  async getPriceModifications() {
    if (!this.priceModifications) {
      this.priceModifications = await this.api.doGet(this.path);
    }
    return this.priceModifications;
  }
}) || _class);