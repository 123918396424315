var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
export let SignalRTrackingService = (_dec = inject(ApiService), _dec(_class = class SignalRTrackingService {
  constructor(api) {
    this.path = 'SignalRTracking';
    this.api = api;
  }
  async processSignalRTracking(sendNotification) {
    return await this.api.doPut(`${this.path}/ProcessSignalRTracking/${sendNotification}`);
  }
}) || _class);