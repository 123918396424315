var _dec, _class;
import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';
export let ChargebackService = (_dec = inject(ApiService, Helper), _dec(_class = class ChargebackService {
  constructor(api, helper) {
    this.path = 'Chargeback';
    this.userChargeback = void 0;
    this.api = api;
    this.helper = helper;
  }
  async userHasChargeback(userId) {
    if (this.helper.isBoolean(this.userChargeback)) return this.userChargeback;
    this.userChargeback = await this.helper.fetchData(this.api, `${this.path}/UserHasChargeback/${userId}`, 'userHasChargeback');
    return this.userChargeback;
  }
}) || _class);