export class ReasonType {
  constructor(reason, type, title, defaultValue, callback) {
    this.reason = reason;
    this.type = type;
    this.title = title;
    this.defaultValue = defaultValue;
    this.callback = callback;
  }
}
export const NotificationType = {
  Info: 'info',
  Error: 'error',
  Succes: 'succcess'
};